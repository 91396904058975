import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    inject,
    signal,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import {
    BhcApiService,
    BhcScoreIntervalTypeName,
    BhcScoreOnDateModel,
    DateInterval,
} from '@quipex/shared/data';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexGrid,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    NgApexchartsModule,
} from 'ng-apexcharts';
import { finalize } from 'rxjs';
import { LoaderComponent } from '../loader.component';

export type BhcTimeChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    dataLabels: ApexDataLabels;
    grid: ApexGrid;
    stroke: ApexStroke;
    title: ApexTitleSubtitle;
    tooltip: ApexTooltip;
};

enum ChartSeriesNameEnum {
    IntegrityScore = 'Integrity score',
    RiskCultureScore = 'Risk Culture Score',
    PortfolioAverage = 'Portfolio average',
    IndustryAverage = 'Industry average',
}

@Component({
    selector: 'qpx-bhc-time-chart',
    templateUrl: './bhc-time-chart.component.html',
    styleUrls: ['./bhc-time-chart.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        NgApexchartsModule,
        LoaderComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BhcTimeChartComponent implements OnInit, OnChanges {
    @Input() buildingId?: string;
    @Input() cardHeight!: number;
    @Input() title = '';
    @Input() showPortfolioIndustryDropdown = false;

    isLoading = signal(false);

    bhcScorePayload: any = {};
    allChartOptions: Partial<BhcTimeChartOptions> | any;

    bhcScoreIntervalTypeList: { id: number; name: string }[] = [
        {
            id: DateInterval.LastThreeMonths,
            name: BhcScoreIntervalTypeName.LastThreeMonths,
        },
        {
            id: DateInterval.LastSixMonths,
            name: BhcScoreIntervalTypeName.LastSixMonths,
        },
        {
            id: DateInterval.LastYear,
            name: BhcScoreIntervalTypeName.LastYear,
        },
        {
            id: DateInterval.AllTime,
            name: BhcScoreIntervalTypeName.AllTime,
        },
    ];

    bhcScoreIntervalCtrl = new FormControl(this.bhcScoreIntervalTypeList[1]);
    portfolioIndustryCtrl = new FormControl(true);

    chartTypes = [
        { id: 1, name: 'Integrity Score' },
        { id: 2, name: 'Risk Culture Score' },
    ];
    chartSeriesNameEnum = ChartSeriesNameEnum;

    chartOptions: Partial<BhcTimeChartOptions> | any = {
        legend: {
            show: false,
        },
        series: [
            {
                name: ChartSeriesNameEnum.IntegrityScore,
                data: [],
                color: '#02ADD7',
            },
            {
                name: ChartSeriesNameEnum.PortfolioAverage,
                data: [],
                color: '#7C9098',
            },
            {
                name: ChartSeriesNameEnum.IndustryAverage,
                data: [],
                color: '#CABEFF',
            },
        ],
        tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
                return this.getChartTooltip(
                    series,
                    seriesIndex,
                    dataPointIndex,
                    w
                );
            },
        },
        markers: {
            size: 6,
        },
        stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 0],
        },
        title: {
            text: 'Building Health Check Score vs Time',
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM yy',
                    day: '',
                },
                style: {
                    colors: ['#758C95'],
                    fontSize: '10px',
                    fontFamily: 'normal normal medium 10px/52px Inter',
                },
            },
            title: {
                text: '',
            },
            tooltip: {
                formatter: function (val: any, opts: any) {
                    const date = new Date(
                        opts.w.config.series[opts.seriesIndex].data[
                            opts.dataPointIndex
                        ][0]
                    );
                    return date.toLocaleString('default', { month: 'short' });
                },
            },
        },
        yaxis: {
            title: {
                text: 'Score',
            },
            tickAmount: 4,
            min: 0,
            max: 100,
        },
    };

    private readonly bhcService = inject(BhcApiService);

    ngOnInit(): void {
        this.allChartOptions = { ...this.chartOptions };
        this.bhcScorePayload = {
            buildingId: this.buildingId,
            chartType: this.chartTypes[0].id,
            bhcScoreIntervalType: DateInterval.LastSixMonths,
        };

        this.getIntervalScores();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['buildingId'] &&
            !changes['buildingId'].firstChange &&
            changes['buildingId'].currentValue
        ) {
            this.bhcScorePayload.buildingId = this.buildingId;
            this.bhcScorePayload.chartType = this.chartTypes[0].id;
            this.bhcScorePayload.bhcScoreIntervalType =
                DateInterval.LastSixMonths;
            this.bhcScoreIntervalCtrl.setValue(
                this.bhcScoreIntervalTypeList[1]
            );
            this.portfolioIndustryCtrl.setValue(true);
            this.getIntervalScores();
        }
    }

    portfolioIndustrySelect(_: any): void {
        this.updateChartOptions();
    }

    onBhcScoreIntervalTypeChange(event: MatSelectChange): void {
        this.bhcScorePayload.bhcScoreIntervalType = event.value.id;
        this.getIntervalScores();
    }

    chartTypeChange(chartType: number): void {
        this.bhcScorePayload.chartType = chartType;
        this.getIntervalScores();
    }

    private getChartTooltip(
        series: any,
        seriesIndex: any,
        dataPointIndex: any,
        w: any
    ): string {
        let integrityScore: any = [];
        let showPortfolioTrendScore = false;
        let showIndustryTrendScore = false;
        let portfolioTrendScore = undefined;
        let industryTrendScore = undefined;

        w.config.series.forEach((s: any) => {
            switch (s.name) {
                case ChartSeriesNameEnum.IntegrityScore:
                    integrityScore = s.data[dataPointIndex];
                    break;

                case ChartSeriesNameEnum.PortfolioAverage:
                    showPortfolioTrendScore = s.data.some(
                        (element: any) => element[1]
                    );
                    portfolioTrendScore = s.data[dataPointIndex];
                    if (portfolioTrendScore && portfolioTrendScore[1]) {
                        portfolioTrendScore = portfolioTrendScore[1];
                    } else {
                        portfolioTrendScore = 'No data';
                    }
                    break;

                case ChartSeriesNameEnum.IndustryAverage:
                    showIndustryTrendScore = s.data.some(
                        (element: any) => element[1]
                    );
                    industryTrendScore = s.data[dataPointIndex];
                    if (industryTrendScore && industryTrendScore[1]) {
                        industryTrendScore = industryTrendScore[1];
                    } else {
                        industryTrendScore = 'No data';
                    }
                    break;
                default:
                    break;
            }
        });

        const date = new Date(integrityScore[0]);
        const date_String: string = date.toLocaleDateString('default', {
            month: 'short',
        });

        return (
            '<div align="left" style="padding: 10px;"><b>' +
            date_String +
            '</b></br>' +
            '<div style="display:inline-flex;align-items:center;gap:24px;width: 100%">' +
            '<div style="display:flex;align-items: center;flex-grow: 1"><span style="display: inline-block; width: 10px; height: 10px; background-color: #02add7; border-radius: 50%; margin-right: 4px;"></span>' +
            (this.bhcScorePayload.chartType === 1
                ? 'Integrity score:'
                : 'Risk Culture Score') +
            '</div><b>' +
            integrityScore[1] +
            '</b></div>' +
            (showPortfolioTrendScore
                ? (portfolioTrendScore != 'No data'
                      ? '</b></br>' +
                        '<div style="display: inline-flex; align-items: center; gap:24px; width: 100%">' +
                        '<div style="display:flex;align-items: center;flex-grow: 1"><span style="display: inline-block; width: 10px; height: 10px; background-color: #7C9098; border-radius: 50%; margin-right: 4px;"></span>' +
                        'Portfolio average:</div><b>' +
                        portfolioTrendScore
                      : '') + '</b></div>'
                : '' +
                  (showIndustryTrendScore
                      ? industryTrendScore != 'No data'
                          ? '</b></br>' +
                            '<div style="display: inline-flex; align-items: center; gap:24px; width: 100%">' +
                            '<div style="display:flex;align-items: center;flex-grow: 1"><span style="display: inline-block; width: 10px; height: 10px; background-color: #CABEFF; border-radius: 50%; margin-right: 4px;"></span>' +
                            'Industry average:</div><b>' +
                            industryTrendScore
                          : ''
                      : '') +
                  '</div>')
        );
    }

    private getIntervalScores(): void {
        this.isLoading.set(true);
        this.bhcService
            .getIntervalScores(this.bhcScorePayload)
            .pipe(finalize(() => this.isLoading.set(false)))
            .subscribe({
                next: (data) => {
                    this.chartOptions.series = [
                        this.allChartOptions.series[0],
                        this.allChartOptions.series[1],
                        this.allChartOptions.series[2],
                    ];

                    this.chartOptions.series.forEach((s: any) =>
                        s.data.splice(0)
                    );

                    data.forEach((element: BhcScoreOnDateModel) => {
                        let itemIndex = -1;
                        this.chartOptions.series[0].data.forEach(
                            (x: any, index: number) => {
                                if (x[0] == element.date) itemIndex = index;
                            }
                        );

                        if (element.data != null) {
                            if (itemIndex > -1) {
                                this.chartOptions.series[0].data[itemIndex][1] =
                                    element.data;
                            } else {
                                this.chartOptions.series[0].data.push([
                                    element.date,
                                    element.data,
                                ]);
                            }
                        } else if (itemIndex == -1) {
                            this.chartOptions.series[0].data.push([
                                element.date,
                                null,
                            ]);
                        }

                        itemIndex = -1;
                        this.chartOptions.series[1].data.forEach(
                            (x: any, index: number) => {
                                if (x[0] == element.date) itemIndex = index;
                            }
                        );
                        if (element.portfolioTrend != null) {
                            if (itemIndex > -1) {
                                this.chartOptions.series[1].data[itemIndex][1] =
                                    element.portfolioTrend;
                            } else {
                                this.chartOptions.series[1].data.push([
                                    element.date,
                                    element.portfolioTrend,
                                ]);
                            }
                        } else {
                            if (itemIndex == -1) {
                                this.chartOptions.series[1].data.push([
                                    element.date,
                                    null,
                                ]);
                            }
                        }

                        itemIndex = -1;
                        this.chartOptions.series[2].data.forEach(
                            (x: any, index: number) => {
                                if (x[0] == element.date) itemIndex = index;
                            }
                        );
                        if (element.industryTrend) {
                            if (itemIndex > -1) {
                                this.chartOptions.series[2].data[itemIndex][1] =
                                    element.industryTrend;
                            } else {
                                this.chartOptions.series[2].data.push([
                                    element.date,
                                    element.industryTrend,
                                ]);
                            }
                        } else {
                            if (itemIndex == -1) {
                                this.chartOptions.series[2].data.push([
                                    element.date,
                                    null,
                                ]);
                            }
                        }
                    });

                    this.allChartOptions = { ...this.chartOptions };

                    if (this.showPortfolioIndustryDropdown) {
                        this.updateChartOptions();
                    }

                    (this.chartOptions.chart = {
                        height: this.cardHeight, //TODO: fix this set it dynamically
                        type: 'line',
                        toolbar: {
                            show: false,
                        },
                    }),
                        window.dispatchEvent(new Event('resize'));
                },
            });
    }

    private updateChartOptions(): void {
        if (this.portfolioIndustryCtrl.value) {
            this.chartOptions.series = [
                this.allChartOptions?.series[0],
                this.allChartOptions.series[1],
            ];
            this.chartOptions.stroke = {
                width: [5, 7],
                curve: 'straight',
                dashArray: [0, 8],
            };
        } else {
            this.chartOptions.series = [
                this.allChartOptions?.series[0],
                this.allChartOptions.series[2],
            ];
            this.chartOptions.stroke = {
                width: [5, 5],
                curve: 'straight',
                dashArray: [0, 0],
            };
        }
    }
}
