import { Pipe, type PipeTransform } from '@angular/core';

export const OUTPUT_MODE = {
    Street: 'street',
    City: 'city',
    Full: 'full',
} as const;
export type OutputMode = (typeof OUTPUT_MODE)[keyof typeof OUTPUT_MODE];

@Pipe({
    name: 'qpxAddress',
    standalone: true,
})
export class AddressPipe implements PipeTransform {
    transform(value: unknown, mode: OutputMode = OUTPUT_MODE.Full): string {
        if (typeof value === 'string') {
            switch (mode) {
                case OUTPUT_MODE.Street: {
                    const streetAddressFromFullAddress = value.split(',')[0];
                    return streetAddressFromFullAddress;
                }
                case OUTPUT_MODE.City: {
                    const cityFromAddress = value.split(',').slice(1).join('');
                    return cityFromAddress;
                }
                case OUTPUT_MODE.Full:
                default: {
                    const valueAsArr = value.split(',');

                    // if [streetNumber] [streetName], [city], [state], [postcode]
                    //                                                ^ -- remove comma
                    return (
                        (valueAsArr.length === 4 &&
                            `${valueAsArr[0]}, ${valueAsArr[1]}, ${valueAsArr[2]} ${valueAsArr[3]}`) ||
                        value
                    );
                }
            }
        }

        if (!value || !isRecord(value)) return '';

        const {
            streetNumber,
            streetName,
            city,
            state,
            postcode,
            address,
            buildingName,
            buildingAddress,
            buildingCity,
        } = getValuesFromUnknownRecord(value);

        switch (mode) {
            case OUTPUT_MODE.Street: {
                const hasStreetFromKeys = hasAddressKeys({
                    mode: OUTPUT_MODE.Street,
                    streetNumber,
                    streetName,
                });

                const sanitisedStreetAddress = buildingAddress.split(',')[0];
                const sanitisedBuildingName = buildingName.split(',')[0];
                const streetAddressFromFullAddress = address.split(',')[0];

                return (
                    (hasStreetFromKeys && `${streetNumber} ${streetName}`) ||
                    sanitisedStreetAddress ||
                    sanitisedBuildingName ||
                    streetAddressFromFullAddress ||
                    ''
                );
            }
            case OUTPUT_MODE.City: {
                const hasCityFromKeys = hasAddressKeys({
                    mode: OUTPUT_MODE.City,
                    city,
                    state,
                    postcode,
                });

                const cityFromFullAddress = address
                    .split(',')
                    .slice(1)
                    .join('');

                const sanitisedCityLocation = buildingCity.split(',').join('');

                return (
                    (hasCityFromKeys && `${city} ${state} ${postcode}`) ||
                    cityFromFullAddress ||
                    sanitisedCityLocation ||
                    ''
                );
            }

            case OUTPUT_MODE.Full:
            default: {
                const hasAddressFromKeys = hasAddressKeys({
                    mode: OUTPUT_MODE.Full,
                    streetNumber,
                    streetName,
                    city,
                    state,
                    postcode,
                });

                const buildingAddressAndCity =
                    prepareFullAddressFromAddressCity({
                        buildingAddress,
                        buildingCity,
                    });

                return (
                    (hasAddressFromKeys &&
                        `${streetNumber} ${streetName}, ${city}, ${state} ${postcode}`) ||
                    address ||
                    buildingAddressAndCity ||
                    buildingName ||
                    buildingAddress ||
                    ''
                );
            }
        }
    }
}

function hasAddressKeys({
    mode,
    streetNumber,
    streetName,
    city,
    state,
    postcode,
}: {
    mode: OutputMode;
    streetNumber?: string;
    streetName?: string;
    city?: string;
    state?: string;
    postcode?: string;
}) {
    switch (mode) {
        case OUTPUT_MODE.Street: {
            return streetNumber && streetName;
        }

        case OUTPUT_MODE.City: {
            return city && state && postcode;
        }

        case OUTPUT_MODE.Full:
        default: {
            return streetNumber && streetName && city && state && postcode;
        }
    }
}

function prepareFullAddressFromAddressCity({
    buildingAddress,
    buildingCity,
}: {
    buildingAddress: string;
    buildingCity: string;
}) {
    if (!buildingAddress || !buildingCity) return '';

    const sanitisedStreetAddress = buildingAddress.split(',')[0];
    return `${sanitisedStreetAddress}, ${buildingCity}`;
}

function getValuesFromUnknownRecord(value: Record<string, unknown>): {
    streetNumber: string;
    streetName: string;
    city: string;
    state: string;
    postcode: string;
    /** full */
    address: string;
    /** streetNumber and streetName */
    buildingName: string;
    /** streetNumber and streetName OR full */
    buildingAddress: string;
    /** city, state and postcode */
    buildingCity: string;
} {
    /** full */
    const address =
        typeof value['address'] === 'string' ? value['address'] : '';

    const streetNumber =
        typeof value['streetNumber'] === 'string' ? value['streetNumber'] : '';
    const streetName =
        typeof value['streetName'] === 'string' ? value['streetName'] : '';
    const city = typeof value['city'] === 'string' ? value['city'] : '';
    const state = typeof value['state'] === 'string' ? value['state'] : '';
    const postcode =
        typeof value['postcode'] === 'number'
            ? value['postcode'].toString()
            : '';

    /** streetNumber and streetName */
    const buildingName =
        typeof value['buildingName'] === 'string' ? value['buildingName'] : '';

    /** streetNumber and streetName */
    const buildingAddress =
        typeof value['buildingAddress'] === 'string'
            ? value['buildingAddress']
            : '';

    /** city, state and postcode */
    const buildingCity =
        typeof value['buildingCity'] === 'string' ? value['buildingCity'] : '';

    return {
        streetNumber,
        streetName,
        city,
        state,
        postcode,
        address,
        buildingName,
        buildingAddress,
        buildingCity,
    };
}

function isRecord(value: unknown): value is Record<string, unknown> {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
}
