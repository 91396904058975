import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiService } from '@quipex/shared/data';
import { filter, map } from 'rxjs';

export const buildersGuard = () => {
    const userService = inject(UserApiService);
    const router = inject(Router);

    return userService.userMe$.pipe(
        filter((currentUser) => currentUser !== null),
        map((currentUser) => {
            if (currentUser) {
                const isBuilder =
                    currentUser.role?.toLowerCase().indexOf('builder') > -1;
                if (isBuilder) {
                    router.navigateByUrl('/buildings');
                    return false;
                }
                return true;
            }
            return false;
        })
    );
};

/* TODO: When role is returned in the token the below should be implemented
    as it will be more efficient to query the role from the jwt token claims instead
    of an api request. For the moment only the roleId is returned in the jwt so we must make a api request
    to get the role itself
    */
// canActivate(): boolean {
//     const token = this.msalService.instance.idTokenClaims;
//     if (token) {
//       const role = token['extension_role'] as string;
//       if (role.toLowerCase().indexOf('builder') > -1) { // don't allow access for builde role
//         this.router.navigateByUrl('/buildings');
//         return false;
//       }
//     }
//     return true;
// }
