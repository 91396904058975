export interface ISearchDocsRequest {
    searchTerm: string;
    identifier: string;
    searchType: SearchType;
    size?: number;
    userGuid: string;
}

export enum SearchType {
    Organization = 1,
    Building = 2,
}
