import { Injectable } from '@angular/core';
import { IDuplicatesDialogData } from './duplicates-modal.component';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DuplicatesModalService {
    dialogVisible$ = new Subject<boolean>();
    dialogDataChange$ = new BehaviorSubject<IDuplicatesDialogData | null>(null);

    setDialogVisible(visible: boolean): void {
        this.dialogVisible$.next(visible);
    }

    updateDialogData(data: IDuplicatesDialogData | null): void {
        this.dialogDataChange$.next(data);
    }
}
