import dayjs from 'dayjs';
import { EsmCategoryEnum } from './esm.enum';

export enum EsmFrequencyEnum {
    Weekly = 1,
    Monthly = 2,
    ThreeMonthly = 3,
    SixMonthly = 4,
    Annual = 5,
    ThreeYearly = 6,
    FiveYearly = 7,
    SixYearly = 8,
    TenYearly = 9,
    TwelveYearly = 10,
    TwentyFourYearly = 11,
    TwentyFiveYearly = 12,
    ThirtyYearly = 13,
}

export enum EsmFrequencyNamesEnum {
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    ThreeMonthly = 'Three monthly',
    SixMonthly = 'Six monthly',
    Annual = 'Yearly',
    ThreeYearly = 'Three yearly',
    FiveYearly = 'Five yearly',
    SixYearly = 'Six yearly',
    TenYearly = 'Ten yearly',
    TwelveYearly = 'Twelve yearly',
    TwentyFourYearly = 'Twenty four yearly',
    TwentyFiveYearly = 'Twenty five yearly',
    ThirtyYearly = 'Thirty yearly',
}

export interface IEsmFrequency {
    id: EsmFrequencyEnum;
    name: EsmFrequencyNamesEnum;
    label: string;
}

export const esmFrequencyOptions: IEsmFrequency[] = [
    {
        id: EsmFrequencyEnum.Weekly,
        name: EsmFrequencyNamesEnum.Weekly,
        label: '1w',
    },
    {
        id: EsmFrequencyEnum.Monthly,
        name: EsmFrequencyNamesEnum.Monthly,
        label: '1m',
    },
    {
        id: EsmFrequencyEnum.ThreeMonthly,
        name: EsmFrequencyNamesEnum.ThreeMonthly,
        label: '3m',
    },
    {
        id: EsmFrequencyEnum.SixMonthly,
        name: EsmFrequencyNamesEnum.SixMonthly,
        label: '6m',
    },
    {
        id: EsmFrequencyEnum.Annual,
        name: EsmFrequencyNamesEnum.Annual,
        label: '1y',
    },
    {
        id: EsmFrequencyEnum.ThreeYearly,
        name: EsmFrequencyNamesEnum.ThreeYearly,
        label: '3y',
    },
    {
        id: EsmFrequencyEnum.FiveYearly,
        name: EsmFrequencyNamesEnum.FiveYearly,
        label: '5y',
    },
    {
        id: EsmFrequencyEnum.SixYearly,
        name: EsmFrequencyNamesEnum.SixYearly,
        label: '6y',
    },
    {
        id: EsmFrequencyEnum.TenYearly,
        name: EsmFrequencyNamesEnum.TenYearly,
        label: '10y',
    },
    {
        id: EsmFrequencyEnum.TwelveYearly,
        name: EsmFrequencyNamesEnum.TwelveYearly,
        label: '12y',
    },
    {
        id: EsmFrequencyEnum.TwentyFourYearly,
        name: EsmFrequencyNamesEnum.TwentyFourYearly,
        label: '20y',
    },
    {
        id: EsmFrequencyEnum.TwentyFiveYearly,
        name: EsmFrequencyNamesEnum.TwentyFiveYearly,
        label: '25y',
    },
    {
        id: EsmFrequencyEnum.ThirtyYearly,
        name: EsmFrequencyNamesEnum.ThirtyYearly,
        label: '30y',
    },
];

export const esmFrequencyHierarchy: EsmFrequencyEnum[] = [
    EsmFrequencyEnum.Weekly,
    EsmFrequencyEnum.Monthly,
    EsmFrequencyEnum.ThreeMonthly,
    EsmFrequencyEnum.SixMonthly,
    EsmFrequencyEnum.Annual,
    EsmFrequencyEnum.ThreeYearly,
    EsmFrequencyEnum.FiveYearly,
    EsmFrequencyEnum.SixYearly,
    EsmFrequencyEnum.TenYearly,
    EsmFrequencyEnum.TwelveYearly,
    EsmFrequencyEnum.TwentyFourYearly,
    EsmFrequencyEnum.TwentyFiveYearly,
    EsmFrequencyEnum.ThirtyYearly,
];

export enum EsmStandardEnum {
    Standard_1851_2012 = 1,
    Standard_1851_2005 = 2,
    Standard_3666_2011 = 3,
    Standard_29932_2019 = 4,
    BCA = 5,
}

export enum EsmStandardNamesEnum {
    Standard_1851_2012 = '1851-2012',
    Standard_1851_2005 = '1851-2005',
    Standard_3666_2011 = '3666.3- 2011',
    Standard_29932_2019 = '2993.2- 2019',
    BCA = 'BCA',
}

export interface IEsmStandard {
    id: EsmStandardEnum;
    name: EsmStandardNamesEnum;
}

export const esmStandards: IEsmStandard[] = [
    {
        id: EsmStandardEnum.Standard_1851_2012,
        name: EsmStandardNamesEnum.Standard_1851_2012,
    },
    {
        id: EsmStandardEnum.Standard_1851_2005,
        name: EsmStandardNamesEnum.Standard_1851_2005,
    },
    {
        id: EsmStandardEnum.Standard_3666_2011,
        name: EsmStandardNamesEnum.Standard_3666_2011,
    },
    {
        id: EsmStandardEnum.Standard_29932_2019,
        name: EsmStandardNamesEnum.Standard_29932_2019,
    },
    { id: EsmStandardEnum.BCA, name: EsmStandardNamesEnum.BCA },
];

export interface IScheduleResponse {
    id: string;
    inspections: IScheduleInspection[];
}

export interface IDefect {
    description: string;
    isCritical: boolean;
}

export interface IScheduleInspection {
    id: string;
    name: string;
    standard: EsmStandardEnum;
    section: string;
    organization: IScheduleAssignedOrg | null;
    isCustom: boolean;
    category: EsmCategoryEnum;
    futureESMs: IScheduleFutureEsm[];
    pastESMs: ISchedulePastEsm[];
    currentESMs: IScheduleCurrentEsm[];
    historicalESMs: { frequency: EsmFrequencyEnum; dueDate: Date }[];
}

export interface IScheduleAssignedOrg {
    id: string; // guid
    name: string;
}

export interface IScheduleFutureEsm {
    id: string;
    frequency: EsmFrequencyEnum;
    dueDate: Date;
}

export interface ISchedulePastEsm {
    id: string;
    dueDate: Date;
    isOverdue: boolean;
    isComplete: boolean;
    hasUnresolvedDefects: boolean;
    hasResolvedDefects: boolean;
    taskId: string | null;
}

export interface IScheduleCurrentEsm {
    frequency: EsmFrequencyEnum;
    isOverdue: boolean;
    isComplete: boolean;
    hasUnresolvedDefects: boolean;
    hasResolvedDefects: boolean;
    hasUpcoming: boolean;
}

export interface IEsmListResponse {
    occupancyCertificateDate: Date;
    esmGroupedBySafetyMeasure: IEsmListItem[];
    isAnnualStatementState: boolean;
}

export interface IEsmListItem {
    safetyMeasure: string;
    esmMasters: IEsmMaster[];
}

export interface IEsmMaster {
    id: string;
    standard: EsmStandardEnum | null;
    frequencies: EsmFrequencyEnum[];
}

export interface ISchedulePostModel {
    buildingId: string;
    currentDate: dayjs.Dayjs;
    esms: IScheduleEsmPost[];
    esmsCustom: IScheduleCustomEsmPost[];
    scheduleYear: number;
}

export interface ISchedulePutModel {
    scheduleId: string;
    currentDate: dayjs.Dayjs;
    esms: IScheduleEsmPost[];
    esmsCustom: IScheduleCustomEsmPost[];
    scheduleYear: number;
}

export interface IScheduleEsmPost {
    id: string;
    orgId: number | null;
    inspections: IScheduleInspectionPost[];
}

export interface IScheduleCustomEsmPost {
    name: string;
    orgId: number | null;
    inspections: IScheduleInspectionPost[];
}

export interface IScheduleInspectionPost {
    frequency: EsmFrequencyEnum;
    months: number[]; // 1-12
}
