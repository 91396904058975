import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    inject,
    output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    MatAutocompleteModule,
    MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
    AddressApiService,
    IAddressResponse,
    ISuggestAddressResponse,
} from '@quipex/shared/data';
import {
    BehaviorSubject,
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    of,
    switchMap,
} from 'rxjs';
import { transformAddress } from '@quipex/shared/helpers';

@Component({
    selector: 'qpx-address-search',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
    ],
    templateUrl: './address-search.component.html',
    styleUrls: ['./address-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchComponent implements OnInit, OnChanges {
    @Input() addressToRestore!: IAddressResponse | null;

    addressSelect = output<IAddressResponse>();

    form!: FormGroup;
    filteredAddresses$ = new BehaviorSubject<ISuggestAddressResponse[]>([]);

    private readonly _minNumberOfCharsToTrigerSearch = 3;

    private readonly destroyRef = inject(DestroyRef);
    private readonly addressService = inject(AddressApiService);
    private readonly formBuilder = inject(FormBuilder);

    get addressSearchCtrl(): FormControl {
        return this.form.controls['addressSearch'] as FormControl;
    }

    ngOnInit(): void {
        this.configureForm();
        this.configureAddressSearch();

        if (this.addressToRestore) {
            this.restoreAddress({
                id: this.addressToRestore.id,
                address: transformAddress(
                    this.addressToRestore.properties.formattedAddress
                ),
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['addressToRestore'] &&
            !changes['addressToRestore'].firstChange &&
            !changes['addressToRestore'].currentValue
        ) {
            this.restoreAddress(null);
        }
    }

    displayFn(item: ISuggestAddressResponse): string {
        if (!item?.address) return '';

        return item.address || '';
    }

    onAddressSelected(event: MatAutocompleteSelectedEvent): void {
        // Get full address
        this.addressService.getAddressDetails(event.option.value.id).subscribe({
            next: (res) => {
                this.addressSelect.emit(res);
            },
        });
    }

    private configureForm(): void {
        this.form = this.formBuilder.group({
            addressSearch: [''],
        });
    }

    private configureAddressSearch(): void {
        this.addressSearchCtrl.valueChanges
            .pipe(
                debounceTime(350),
                distinctUntilChanged(),
                filter((value) => {
                    return (
                        typeof value === 'string' &&
                        value.trim() !== '' &&
                        value.trim().length >=
                            this._minNumberOfCharsToTrigerSearch
                    );
                }),
                switchMap((value) => {
                    return this.addressService.suggestAddress(value).pipe(
                        catchError((err) => {
                            return of(null);
                        })
                    );
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((response) => {
                if (!response?.length) {
                    response = [{ id: '0' } as ISuggestAddressResponse];
                }
                response.forEach((address) => {
                    address.address = transformAddress(address.address);
                });
                this.filteredAddresses$.next(response);
            });
    }

    private restoreAddress(address: ISuggestAddressResponse | null): void {
        this.filteredAddresses$.next(address ? [address] : []);
        this.addressSearchCtrl.setValue(address || null);
    }
}
