export const environment = {
    appVersion: 'v1.0.0',
    isProd: false,
    apiBase: 'https://app-quipex-api-uat.azurewebsites.net',
    apiFunctionBase: 'https://app-quipex-fun-uat.azurewebsites.net/api',
    apiAIBase: 'https://app-quipex-ai-qa.azurewebsites.net',
    addressApiBase: 'https://app-quipex-address-api-staging.azurewebsites.net',
    scopes: {
        quipexApi: [
            'https://quipexuat.onmicrosoft.com/e825d3ee-daa0-46e9-a66c-54264951415e/user_impersonation',
        ],
        addressApi: [
            'https://quipexuat.onmicrosoft.com/address-api/address.read',
        ],
        functionApp: [
            'https://quipexuat.onmicrosoft.com/function-app/function-app.read',
        ],
    },
    clientId: 'd2b51ec1-7a4a-4deb-af32-6c63e89e96c3',
    b2cPolicies: {
        signUpSignIn: {
            name: 'B2C_1_signin',
            authority:
                'https://quipexuat.b2clogin.com/quipexuat.onmicrosoft.com/B2C_1_signin',
        },
        editProfile: {
            name: 'B2C_1_quipex_ui_edit_profile',
            authority:
                'https://quipexuat.b2clogin.com/quipexuat.onmicrosoft.com/B2C_1_signin',
        },
        resetPassword: {
            name: 'B2C_1_quipex_ui_password_reset',
            authority:
                'https://quipexuat.b2clogin.com/quipexuat.onmicrosoft.com/B2C_1_signin',
        },
        authorityDomain: 'quipexuat.b2clogin.com',
    },
    sessionConfig: {
        inactiveTimeoutSeconds: 1800,
        idleTimeoutSeconds: 1,
    },
    imagePreviewFileTypes: [
        'jpeg',
        'jpeg',
        'gif',
        'png',
        'apng',
        'svg',
        'bmp',
        'ico',
        'avif',
        'jpg',
    ],
    documentUploadTypes: [
        'bmp',
        'jpeg',
        'jpg',
        'pdf',
        'png',
        'tiff',
        'tif',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'csv',
        'html',
        'htm',
        'mpp',
        'vsdx',
        'dwg',
        'bimx',
    ],
    documentAutoTagTypes: ['jpeg', 'jpg', 'pdf', 'png', 'doc', 'docx'],
    maxFileSizeInMb: 100,
    concurrentFilesUpload: 50, // number of files that can be uploaded at one time
    mapsApiKey: '#{API_KEY}#',
    instrumentationKey: 'd5cd4c34-2f4c-4842-8dee-338c2f172f39',
    autoTagMaxSizeMB: 25,
    clarityProjectId: 'oy58etsqay',
};
