<form class="flex">
    <mat-form-field class="tags-chip-list w-100">
        <mat-chip-grid #chipGrid>
            <mat-chip-row *ngFor="let tag of tags" (removed)="remove(tag)">
                {{ tag.tagName }}
                <button matChipRemove [attr.aria-label]="'remove ' + tag">
                    <mat-icon class="qpx-icon-sm"> close </mat-icon>
                </button>
            </mat-chip-row>
        </mat-chip-grid>

        <input
            data-testid="tag"
            #tagInput
            placeholder="Start typing to add a tag..."
            [formControl]="tagControl"
            [matChipInputFor]="chipGrid"
            [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
        />
        <mat-autocomplete
            #auto="matAutocomplete"
            class="tags-autocomplete"
            (optionSelected)="selectedTag($event)"
        >
            @for (tag of filteredTags | async; track tag; let first = $first) {
                <mat-option [value]="tag">
                    <div class="tag-search-result">
                        <span [ngClass]="{ bold: first }">{{
                            tag.tagName
                        }}</span>
                        <mat-icon class="qpx-icon-sm">add</mat-icon>
                    </div>
                </mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
</form>
