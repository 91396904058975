import { CommonModule } from '@angular/common';
import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    inject,
} from '@angular/core';
import {
    DocumentApiService,
    DownloadDocumentResponse,
    DialogService,
} from '@quipex/shared/data';
import { FileHelper } from '@quipex/shared/helpers';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { environment } from 'src/environments/environment';
import { LoaderComponent } from '../../loader.component';
import {
    NgxExtendedPdfViewerModule,
    NgxExtendedPdfViewerService,
} from 'ngx-extended-pdf-viewer';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Subject, takeUntil } from 'rxjs';

export interface IDocumentPreviewDialogData {
    docGuid: string;
    searchTerm: string;
    dialogTitle: string;
    standaloneDialog: boolean;
}

@Component({
    selector: 'qpx-document-preview',
    templateUrl: './document-preview-modal.component.html',
    styleUrls: ['./document-preview-modal.component.scss'],
    imports: [
        CommonModule,
        MatIconModule,
        MatDialogModule,
        LoaderComponent,
        NgxDocViewerModule,
        NgxExtendedPdfViewerModule,
    ],
})
export class DocumentPreviewModalComponent implements OnInit, OnDestroy {
    @Input() docGuid!: string;
    @Input() searchTerm!: string;

    docUrl!: string;
    isLoaded = false;
    isImage!: boolean;
    fileType!: string;

    private destroy$ = new Subject<void>();

    private dialogService = inject(DialogService);
    private documentService = inject(DocumentApiService);
    private ngxExtendedPdfViewerService = inject(NgxExtendedPdfViewerService);

    /* when document preview dialog is raised from another dialog pass data in via IDocumentPreviewDialogData
    and call it directly via 'this.matDialog.open(DocumentPreviewModalComponent,..'
    instead of standard way of using wrapper 'this.dialogService.openDialog'
   */
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: IDocumentPreviewDialogData,
        private readonly dialogRef: MatDialogRef<DocumentPreviewModalComponent>
    ) {}

    ngOnInit(): void {
        if (this.data?.standaloneDialog) {
            this.docGuid = this.data.docGuid;
            this.searchTerm = this.data.searchTerm;
        }

        this.getFileDownloadUrl(this.docGuid);

        this.dialogService.requestCancel$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.dialogService.closeDialog(false));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    contentLoaded(): void {
        this.isLoaded = true;
        if (this.searchTerm) {
            this.setPdfViewerElements(this.searchTerm);
        }
    }

    setPdfViewerElements(searchTerm: string): void {
        // search via input params
        this.ngxExtendedPdfViewerService.find(searchTerm);

        // we must maniuplate the dom directly here as we don't have access to these elements (3rd party lib)
        const findSearchBar = document.getElementById('primaryViewFind');
        if (findSearchBar != null) {
            findSearchBar.click();
        }

        const highlightAllCheckbox = document.getElementById(
            'findHighlightAll'
        ) as HTMLInputElement;
        if (highlightAllCheckbox) {
            // Enable the checkbox
            highlightAllCheckbox.checked = true;
        }
    }

    getFileDownloadUrl(docGuid?: string | null, docId?: number): void {
        let docGuidArr: string[] = [];
        if (docGuid != null) {
            docGuidArr = [docGuid];
        }
        this.docUrl = '';
        this.documentService
            .getDocumentUrlsToDownload(docGuidArr, docId)
            .subscribe((response: DownloadDocumentResponse) => {
                const url = response.documents[0].downloadUrl;
                const fileName = response.documents[0].fileName;
                this.docUrl = url;
                this.checkFileType(fileName);
            });
    }

    checkFileType(fileName: string): void {
        this.isImage = false;
        this.fileType = FileHelper.extractFileExtension(fileName);

        if (environment.imagePreviewFileTypes.includes(this.fileType)) {
            this.isImage = true;
            this.isLoaded = true;
            this.fileType = 'image';
        }
    }
}
