/* eslint-disable @nx/enforce-module-boundaries */

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { OrganizationApiService, UserApiService } from '@quipex/shared/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { User } from '../models/user.model';

export interface UserState {
    me: User;
    users: Array<User>;
    org: any;
    dashboardTabs: any;
    theOther: User;
}

@Injectable({
    providedIn: 'root',
})
export class UserStore {
    private _initialState: UserState = {
        me: new User(),
        users: new Array<User>(),
        org: null,
        dashboardTabs: null,
        theOther: new User(),
    };

    private readonly stateSubject: BehaviorSubject<UserState> =
        new BehaviorSubject(this._initialState);

    constructor(
        private UserService: UserApiService,
        private orgService: OrganizationApiService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public getState(): Observable<UserState> {
        return this.stateSubject.pipe(distinctUntilChanged());
    }

    public getStateSnapshot(): UserState {
        return this.stateSubject.getValue();
    }

    public select<K extends keyof UserState>(key: K): Observable<UserState[K]> {
        const selectStream = this.stateSubject.pipe(
            map((state: UserState) => {
                return state[key];
            }),
            distinctUntilChanged((currentState, nextState) => {
                return (
                    JSON.stringify(currentState) === JSON.stringify(nextState)
                );
            })
        );
        return selectStream;
    }

    public setState(partialState: Partial<UserState>): void {
        const currentState = this.getStateSnapshot();
        const nextState = Object.assign({}, currentState, partialState);
        this.stateSubject.next(nextState);
    }

    public dispatch(): void {
        this.UserService.getUsersMe()
            .pipe(tap(() => this.hideLoader()))
            .subscribe((user: User) => {
                this.setState({ me: user }); // TODO: Refactor this -> why call api twice just return all info u need from 1 endpoint if going to be used in state
                this.orgService
                    .getOrganization(user.organizationId)
                    .subscribe((org: any) => {
                        this.setState({ org: org });
                    });
            });
    }

    public getUserById(id: string): void {
        this.UserService.getUser(id).subscribe((user: User) => {
            this.setState({ theOther: user });
        });
    }

    private hideLoader(): void {
        const loader = this.document.querySelector('#loader');
        if (loader) {
            const loaderElRef = loader as any;
            if (loaderElRef?.style) {
                loaderElRef.style.display = 'none';
            }
        }
    }
}
