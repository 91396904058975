<div class="qpx-card">
    <div class="portfolio-integrity">
        <div class="portfolio-integrity-header">
            <div class="qpx-card-title">Portfolio Integrity</div>

            <div
                *ngIf="bhcScoreCardOverall.rating > 0; else noDataMessage"
                class="flex wrap justify-between align-center flex-gap-md"
            >
                <span class="qpx-percentage">{{
                    bhcScoreCardOverall.medianScore
                }}</span>

                <div class="flex change-percentage-wrapper">
                    <div
                        *ngIf="
                            bhcScoreCardOverall.lastQuarterScorePercentageChange
                        "
                        class="change-percentage"
                        [ngClass]="
                            bhcScoreCardOverall.lastQuarterScorePercentageChange >=
                            0
                                ? 'positive'
                                : 'negative'
                        "
                    >
                        <span class="change-percentage-number"
                            >{{
                                bhcScoreCardOverall.lastQuarterScorePercentageChange
                            }}%</span
                        >
                        <span class="change-percentage-arrow-icon">
                            <mat-icon
                                class="material-symbols-outlined qpx-text-success"
                                *ngIf="
                                    bhcScoreCardOverall.lastQuarterScorePercentageChange >=
                                    0
                                "
                            >
                                arrow_upward_alt
                            </mat-icon>
                            <mat-icon
                                class="material-symbols-outlined qpx-text-danger"
                                *ngIf="
                                    bhcScoreCardOverall.lastQuarterScorePercentageChange <
                                    0
                                "
                            >
                                arrow_downward_alt
                            </mat-icon>
                        </span>
                        <div class="change-percentage-label">Last quarter</div>
                    </div>

                    <div
                        *ngIf="
                            bhcScoreCardOverall.portfolioAverageScorePercentageChange
                        "
                        class="change-percentage"
                    >
                        <div
                            [ngClass]="
                                bhcScoreCardOverall.portfolioAverageScorePercentageChange >=
                                0
                                    ? 'positive'
                                    : 'negative'
                            "
                        >
                            <span class="change-percentage-number">
                                +{{
                                    bhcScoreCardOverall.portfolioAverageScorePercentageChange
                                }}%
                            </span>
                            <span class="change-percentage-arrow-icon">
                                <mat-icon
                                    class="material-symbols-outlined qpx-text-success"
                                    *ngIf="
                                        bhcScoreCardOverall.portfolioAverageScorePercentageChange >=
                                        0
                                    "
                                >
                                    arrow_upward_alt
                                </mat-icon>
                                <mat-icon
                                    class="material-symbols-outlined qpx-text-danger"
                                    *ngIf="
                                        bhcScoreCardOverall.portfolioAverageScorePercentageChange <
                                        0
                                    "
                                >
                                    arrow_downward_alt
                                </mat-icon>
                            </span>
                            <div class="change-percentage-label">
                                Portfolio Avg
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="building-scores">
            <qpx-building-scores type="integrityScore"></qpx-building-scores>
        </div>
    </div>

    <div class="risk-culture">
        <div class="risk-culture-header">
            <div class="qpx-card-title">Risk Culture</div>

            <div
                *ngIf="
                    bhcCultureScoreCardOverall.rating > 0;
                    else noDataMessage
                "
                class="flex wrap justify-between align-center flex-gap-md"
            >
                <qpx-health-rating
                    class="mat-stars-2x"
                    [rating]="bhcCultureScoreCardOverall.rating"
                ></qpx-health-rating>

                <div
                    *ngIf="
                        bhcCultureScoreCardOverall.lastQuarterScorePercentageChange
                    "
                    class="change-percentage"
                    [ngClass]="
                        bhcCultureScoreCardOverall.lastQuarterScorePercentageChange >=
                        0
                            ? 'positive'
                            : 'negative'
                    "
                >
                    <span class="change-percentage-number"
                        >{{
                            bhcCultureScoreCardOverall.lastQuarterScorePercentageChange
                        }}%</span
                    >
                    <span class="change-percentage-arrow-icon">
                        <mat-icon
                            class="material-symbols-outlined qpx-text-success"
                            *ngIf="
                                bhcCultureScoreCardOverall.lastQuarterScorePercentageChange >=
                                0
                            "
                        >
                            arrow_upward_alt
                        </mat-icon>
                        <mat-icon
                            class="material-symbols-outlined qpx-text-danger"
                            *ngIf="
                                bhcCultureScoreCardOverall.lastQuarterScorePercentageChange <
                                0
                            "
                        >
                            arrow_downward_alt
                        </mat-icon>
                    </span>

                    <div class="change-percentage-label">Last quarter</div>
                </div>
            </div>
        </div>

        <div class="building-scores">
            <qpx-building-scores type="riskCulture"></qpx-building-scores>
        </div>
    </div>
</div>

<ng-template #noDataMessage>
    <div class="no-data-message">No data available.</div>
</ng-template>
