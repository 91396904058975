import { Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { ProfileComponent } from '@quipex/feature/admin';
import { LayoutComponent } from './components/layout/layout.component';
import { dashboardGuard, insightsGuard, rolesGuard, taskGuard } from './guards';
import { buildingsGuard } from './guards/building-guard';

export const appRoutes: Routes = [
    {
        path: '',
        canActivate: [MsalGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('@quipex/feature/dashboard').then(
                        (mod) => mod.dashboardRoutes
                    ),
                canActivate: [dashboardGuard],
            },
            {
                path: 'insights',
                loadChildren: () =>
                    import('@quipex/feature/insights').then(
                        (mod) => mod.insightsRoutes
                    ),
                canActivate: [insightsGuard, buildingsGuard],
            },
            {
                path: 'buildings',
                loadChildren: () =>
                    import('@quipex/feature/buildings').then(
                        (mod) => mod.buildingsRoutes
                    ),
                canActivate: [buildingsGuard],
            },
            {
                path: 'tasks',
                loadChildren: () =>
                    import('@quipex/feature/tasks').then(
                        (mod) => mod.tasksRoutes
                    ),
                canActivate: [taskGuard],
            },
            {
                path: 'keys',
                loadChildren: () =>
                    import('@quipex/feature/keys').then(
                        (mod) => mod.keysRoutes
                    ),
                canActivate: [buildingsGuard],
            },
            {
                path: 'admin',
                loadChildren: () =>
                    import('@quipex/feature/admin').then(
                        (mod) => mod.adminRoutes
                    ),
            },
            { path: 'profile', component: ProfileComponent },
            {
                path: 'roles',
                loadChildren: () =>
                    import('@quipex/feature/roles').then(
                        (mod) => mod.rolesRoutes
                    ),
                canActivate: [rolesGuard],
            },
            {
                path: 'account-settings',
                loadComponent: () =>
                    import(
                        './views/account-settings/account-settings.component'
                    ).then((comp) => comp.AccountSettingsComponent),
            },
            {
                path: '',
                redirectTo: '/dashboard',
                pathMatch: 'full',
            },
        ],
    },
    {
        // Needed for handling redirect after login
        path: 'auth',
        component: MsalRedirectComponent,
    },
    {
        path: 'logout',
        redirectTo: '',
    },
    {
        path: 'not-found',
        loadComponent: () =>
            import('./views/not-found/not-found.component').then(
                (comp) => comp.NotFoundComponent
            ),
    },
    { path: '**', redirectTo: 'not-found' },
];
