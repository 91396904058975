export enum OrganizationTypeEnum {
    Admin = 5,
    Owner = 10,
    MaintenanceContractor = 20,
    Builder = 30,
}

export enum AccessGrantedOrganizationsSortFieldEnum {
    None = -1,
    Organisation = 10,
    OrganisationType = 20,
    Status = 30,
}

export enum BuildingOrganizationAccessStatusEnum {
    Pending = 10,
    Active = 20,
    Inactive = 30,
}

export interface IGetAccessGrantedOrganizationsRequestDTO {
    pageIndex?: number; // used for mat-paginator
    pageSize: number;
    pageNo: number;
    searchTerm: string;
    sortOrder: number;
    sortField: AccessGrantedOrganizationsSortFieldEnum;
}

export interface IGetAccessGrantedOrganizationDetailsRequestDTO {
    grantId: string;
    searchTerm: string;
    pageSize: number;
    pageNo: number;
    pageIndex: number; // UI only
}

export interface IAccessOrganisation {
    id: number;
    name: string;
    identifier: string;
}

export interface IAccessOrganisationSearch {
    id: string;
    companyName: string;
    isGranted: boolean;
    organizationType: OrganizationTypeEnum;
}

export interface IBrantedOrganisationNames {
    id: string;
    name: string;
    isGranted: boolean;
    organizationType: OrganizationTypeEnum;
}

export interface IEditDelegatingBMAccessRequest {
    grantedBuildings: number[];
    grantedId: string;
}

export interface IDelegatingBMAccessCommandRequest {
    buildingIds: any[];
    existingOrganizationId: string;
    newOrganizationName: string;
    newOrganizationType: OrganizationTypeEnum;
    keyContactName: string;
    keyContactEmail: string;
}

export interface IGrantedOrganisationItems {
    buildingOrganizationAccessStatus: BuildingOrganizationAccessStatusEnum;
    category: AccessGrantedOrganizationsSortFieldEnum;
    grantId: string;
    isOrgActive: boolean;
    name: string;
    orgGuid: string;
}

export interface IGetGrantedOrganisationsResponse {
    pageIndex: number;
    totalCount: number;
    totalPages: number;
    items: IGrantedOrganisationItems[];
}

export interface IUserAccess {
    name: string;
    email: string;
    isActive: boolean;
    roleId: number;
    role: string; // UI property
    orgId: any;
    orgIsActive: boolean;
    orgType: number;
    grantBuildings: [];
}
