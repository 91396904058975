import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedService {
    private dataSubject = new BehaviorSubject<any>(null);
    data$ = this.dataSubject.asObservable();

    private queryParamsSubject = new BehaviorSubject<any>(null);
    queryParams$ = this.queryParamsSubject.asObservable();

    private submissionSubject: Subject<void> = new Subject<void>();
    submission$: Observable<void> = this.submissionSubject.asObservable();

    private toggleSubmitButtonSubject: Subject<boolean> =
        new BehaviorSubject<boolean>(true);
    toggleSubmitButton$ = this.toggleSubmitButtonSubject.asObservable();

    updateData(data: any) {
        this.dataSubject.next(data);
        this.toggleIsSubmitDisabled(false);
    }

    updateQueryParams(queryParams: any) {
        this.queryParamsSubject.next(queryParams);
    }

    // Method to trigger a submission event (i.e from the modal)
    submit(): void {
        this.submissionSubject.next();
    }

    resetModalState(): void {
        this.dataSubject.next(null);
        this.submissionSubject = new Subject<void>(); // Reset to empty state
        this.submission$ = this.submissionSubject.asObservable(); // Update observable
        this.toggleIsSubmitDisabled(true);
    }

    toggleIsSubmitDisabled(isDisabled: boolean): void {
        this.toggleSubmitButtonSubject.next(isDisabled);
    }
}
