export enum DateInterval {
    LastThreeMonths = 1,
    LastSixMonths = 2,
    LastYear = 3,
    AllTime = 4,
}

export enum BhcScoreIntervalTypeName {
    LastThreeMonths = 'Last 3 months',
    LastSixMonths = 'Last 6 months',
    LastYear = 'Last 12 months',
    AllTime = 'All time',
}
