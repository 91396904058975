import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    inject,
    input,
    signal,
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router } from '@angular/router';
import {
    BhcApiService,
    EsmCategoryEnum,
    EsmCategoryNamesEnum,
    esmCategoryyOptions,
    IGetBhcIntegrityScoreResponse,
} from '@quipex/shared/data';
import { StringHelper } from '@quipex/shared/helpers';
import { finalize } from 'rxjs';
import { LoaderComponent } from '../loader.component';

@Component({
    selector: 'qpx-understand-your-score',
    imports: [CommonModule, MatExpansionModule, LoaderComponent],
    templateUrl: './understand-your-score.component.html',
    styleUrls: ['./understand-your-score.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnderstandUourScoreComponent {
    @Input({ required: true }) set buildingId(id: any) {
        this.getScoreData(id);
    }
    @Input({ required: true }) buildingGuid!: string;
    canNavigateToMaintenance = input<boolean>(false);

    isLoading = signal(false);
    scoreData = signal<IGetBhcIntegrityScoreResponse | null>(null);

    private readonly router = inject(Router);
    private readonly bhcService = inject(BhcApiService);

    navigateToEsmTab(category: EsmCategoryEnum, masterName: string): void {
        if (this.canNavigateToMaintenance()) {
            this.router.navigateByUrl(
                `buildings/${this.buildingGuid}/maintenance`,
                {
                    state: {
                        category,
                        masterName,
                    },
                }
            );
        }
    }

    private getScoreData(buildingId: number): void {
        this.isLoading.set(true);
        this.bhcService
            .getIntegrityScore(buildingId)
            .pipe(finalize(() => this.isLoading.set(false)))
            .subscribe({
                next: (response) => {
                    this.formatText(response);

                    this.scoreData.set(response);
                },
            });
    }

    private formatText(response: IGetBhcIntegrityScoreResponse) {
        response.integrityScores.defectsDetails?.forEach((defect) => {
            defect.descriptionFormatted = StringHelper.formatText(defect.name);
            defect.categoryName = this.getCategoryLabel(defect.category);
        });
        response.otherRisks.defectsDetails?.forEach((defect) => {
            defect.descriptionFormatted = StringHelper.formatText(defect.name);
            defect.categoryName = this.getCategoryLabel(defect.category);
        });
    }

    private getCategoryLabel(
        category: EsmCategoryEnum
    ): EsmCategoryNamesEnum | null {
        return (
            esmCategoryyOptions.find((option) => option.id === category)
                ?.name ?? null
        );
    }
}
