import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { User, UserStore, BuildingStatus } from '@quipex/shared/data';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hasBuilderPermissionToEdit]',
    standalone: true,
})
export class HasBuilderPermissionToEditDirective {
    private currentUser!: User;
    private buildingStatusId!: BuildingStatus | undefined;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userStore: UserStore
    ) {}

    @Input()
    set hasBuilderPermissionToEdit(val: BuildingStatus | undefined) {
        this.userStore.select('me').subscribe((user) => {
            if (user.role != null) {
                this.buildingStatusId = val;
                this.currentUser = user;
                this.updateView();
            }
        });
    }

    private updateView(): void {
        this.viewContainer?.clear();
        if (this.checkPermission()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    private checkPermission() {
        if (this.currentUser && this.currentUser.role) {
            const currentUserRole = this.currentUser.role.toLowerCase();
            if (
                !currentUserRole.includes('builder') ||
                (this.buildingStatusId != undefined &&
                    this.buildingStatusId === BuildingStatus.UNDERCONSTRUCTION)
            ) {
                return true;
            }
        }
        this.buildingStatusId = undefined;
        return false;
    }
}
