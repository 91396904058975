import { InjectionToken } from '@angular/core';

export const APP_CONFIG_TOKEN = new InjectionToken<AppConfigModel>(
    'app.config'
);

export interface AppConfigModel {
    appVersion: string;
    isProd: boolean;
    apiBase: string;
    apiFunctionBase: string;
    addressApiBase: string;
    scopes: {
        [apiName: string]: string[]; // New composite structure for API scopes
    };
    clientId: string;
    b2cPolicies: {
        signUpSignIn: {
            name: string;
            authority: string;
        };
        editProfile: {
            name: string;
            authority: string;
        };
        resetPassword: {
            name: string;
            authority: string;
        };
        authorityDomain: string;
    };
    sessionConfig: {
        inactiveTimeoutSeconds: number;
        idleTimeoutSeconds: number;
    };
}
