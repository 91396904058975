export class StringHelper {
    static formatText(text: string): string {
        // Replace **text** with <strong>text</strong>
        text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        // Replace \n with <br>
        text = text
            .replace(/\n/g, '<br>')
            .replace(/^### (.*$)/gm, '<h3>$1</h3>') // Convert ### to <h3>
            .replace(/^## (.*$)/gm, '<h2>$1</h2>') // Convert ## to <h2>
            .replace(/^# (.*$)/gm, '<h1>$1</h1>'); // Convert # to <h1>

        return text;
    }
}
