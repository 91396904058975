import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { UserStore } from '@quipex/shared/data';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@Component({
    selector: 'qpx-layout',
    imports: [CommonModule, RouterModule, SidebarComponent, HeaderComponent],
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    isLoading = signal(true);
    menuExpanded = signal(false);

    private readonly destroyRef = inject(DestroyRef);
    private readonly userStore = inject(UserStore);

    ngOnInit(): void {
        this.userStore
            .select('me')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (response) => {
                    if (response?.role) {
                        this.isLoading.set(false);
                    }
                },
            });
    }

    onToggleChange(expanded: boolean): void {
        this.menuExpanded.set(expanded);
    }
}
