import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ApiServiceBase } from '../api-service-base';
import { IGetESMDataPointsByCategoryResponse } from '../models/typings/esm/esm-get-masters-by-category-response';
import {
    IEsmListResponse,
    ISchedulePostModel,
    ISchedulePutModel,
    IScheduleResponse,
} from '../models/typings/esm/esm-schedule.interface';

@Injectable({
    providedIn: 'root',
})
export class EsmApiService extends ApiServiceBase {
    private _apiBase = `${this.appConfig.apiBase}/api/esms`;

    getEsmDetails(postBody: any): Observable<IScheduleResponse> {
        const url = `${this._apiBase}/details`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getEsmDetailsForClone(postBody: any): Observable<IScheduleResponse> {
        const url = `${this._apiBase}/details-for-clone`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getEsmList(buildingId: string): Observable<IEsmListResponse> {
        const url = `${this._apiBase}/${buildingId}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    createSchedule(model: ISchedulePostModel): Observable<any> {
        const url = `${this._apiBase}`;
        return this.http.post<any>(url, model).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    updateSchedule(model: ISchedulePutModel): Observable<any> {
        const url = `${this._apiBase}`;
        return this.http.put<any>(url, model).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getESMDataPointsByCategory(
        buildingGuid: string = ''
    ): Observable<IGetESMDataPointsByCategoryResponse[]> {
        return this.http
            .get<any>(
                `${this._apiBase}/esm-data-points-by-category?buildingId=${buildingGuid}`
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }
}
