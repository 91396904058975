import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiService } from '@quipex/shared/data';
import { filter, map } from 'rxjs';

export const dashboardGuard = () => {
    const userService = inject(UserApiService);
    const router = inject(Router);

    return userService.userMe$.pipe(
        filter((currentUser) => currentUser !== null),
        map((currentUser) => {
            if (currentUser) {
                const isBuilder =
                    currentUser.role?.toLowerCase().indexOf('builder') > -1;
                const isUnitOwner =
                    currentUser.role?.toLowerCase().indexOf('unit owner read') >
                    -1;
                const isMaintenanceContractor =
                    currentUser.role?.toLowerCase().indexOf('maintenance') > -1;

                const isOwnerGeneral =
                    currentUser.role?.toLowerCase().indexOf('owner general') >
                    -1;

                if (isBuilder || isUnitOwner || isMaintenanceContractor) {
                    router.navigateByUrl('/buildings');
                    return false;
                }

                if (isOwnerGeneral) {
                    router.navigateByUrl('/tasks');
                    return false;
                }
                return true;
            }
            return false;
        })
    );
};
