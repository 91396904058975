import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BHCOverallScoreCard } from '@quipex/shared/data';
import { HealthRatingComponent } from '../../../../../shared/components/src/lib/health-rating/health-rating.component';
import { BuildingScoresComponent } from '../building-scores/building-scores.component';

@Component({
    selector: 'qpx-overal-bhc-score-widget',
    imports: [
        CommonModule,
        MatIconModule,
        HealthRatingComponent,
        BuildingScoresComponent,
    ],
    templateUrl: './overal-bhc-score-widget.component.html',
    styleUrls: ['./overal-bhc-score-widget.component.scss'],
})
export class OveralBhcScoreWidgetComponent {
    @Input() bhcScoreCardOverall!: BHCOverallScoreCard;
    @Input() bhcCultureScoreCardOverall!: BHCOverallScoreCard;
}
