import { BuildingManualCreationStatus, BuildingStatus } from './enums.model';
import { IGetBuildingDetails } from './typings/buildings.interface';
export class AddNewBuilding {
    currentUserAdId!: string;
    buildingDTO: BuildingDTO;
    constructor() {
        this.buildingDTO = new BuildingDTO();
    }

    updatedBuildingDTO(building: IGetBuildingDetails) {
        this.buildingDTO.about = building.about ?? this.buildingDTO.about;
        this.buildingDTO.address = building.address ?? this.buildingDTO.address;

        // FIXME: addressId prop is never returned

        this.buildingDTO.builder = building.builder ?? this.buildingDTO.builder;
        this.buildingDTO.buildingId = building.buildingId;
        this.buildingDTO.buildingName =
            building.buildingName ?? this.buildingDTO.buildingName;
        this.buildingDTO.buildingStatusId = building.buildingStatusId;
        this.buildingDTO.cityName =
            building.cityName ?? this.buildingDTO.cityName;
        this.buildingDTO.classId = building.classId ?? this.buildingDTO.classId;
        this.buildingDTO.classes = building.classes ?? this.buildingDTO.classes;
        this.buildingDTO.countryName =
            building.countryName ?? this.buildingDTO.countryName;
        this.buildingDTO.creationStatus = building.creationStatus;

        if (building.developerDirectors?.length) {
            building.developerDirectors.forEach((d) => {
                const director = new Director();
                if (d.name) {
                    director.name = d.name;
                    this.buildingDTO.developerDirectors.push(director);
                }
            });
        }

        this.buildingDTO.developerDirectorNames =
            building.developerDirectorNames ??
            this.buildingDTO.developerDirectorNames;
        this.buildingDTO.developerName =
            building.developerName ?? this.buildingDTO.developerName;
        this.buildingDTO.effectiveHeight =
            building.effectiveHeight ?? this.buildingDTO.effectiveHeight;
        this.buildingDTO.floorArea =
            building.floorArea ?? this.buildingDTO.floorArea;

        // FIXME: keyDocuments prop always returns null and there is a mismatch between api call type and dto

        this.buildingDTO.latitude =
            building.latitude ?? this.buildingDTO.latitude;
        this.buildingDTO.latitude =
            building.latitude ?? this.buildingDTO.latitude;
        this.buildingDTO.longitude =
            building.longitude ?? this.buildingDTO.longitude;
        this.buildingDTO.maxOccupants =
            building.maxOccupants ?? this.buildingDTO.maxOccupants;
        this.buildingDTO.noOfSoleOccupancyUnits =
            building.noOfSoleOccupancyUnits ??
            this.buildingDTO.noOfSoleOccupancyUnits;
        this.buildingDTO.occupancyCertificateDate =
            building.occupancyCertificateDate ??
            this.buildingDTO.occupancyCertificateDate;
        this.buildingDTO.postcode = building.postcode
            ? building.postcode.toString()
            : this.buildingDTO.postcode;
        this.buildingDTO.propertyOwner =
            building.propertyOwner ?? this.buildingDTO.propertyOwner;
        this.buildingDTO.riseInStoreys =
            building.riseInStoreys ?? this.buildingDTO.riseInStoreys;
        this.buildingDTO.siteArea =
            building.siteArea ?? this.buildingDTO.siteArea;
        this.buildingDTO.state = building.state ?? this.buildingDTO.state;
        this.buildingDTO.stateName =
            building.stateName ?? this.buildingDTO.stateName;
        this.buildingDTO.streetName =
            building.streetName ?? this.buildingDTO.streetName;
        this.buildingDTO.streetNumber =
            building.streetNumber ?? this.buildingDTO.streetNumber;
        this.buildingDTO.title = building.title ?? this.buildingDTO.title;
    }
}

export class UpdateBuildingDTO {
    buildingId = '';
    about = '';
    title = '';
    classId: string[] = [''];
    siteArea!: number;
    floorArea!: number;
    riseInStoreys!: number;
    effectiveHeight!: number;
    maxOccupants!: string;
    noOfSoleOccupancyUnits!: string;

    developerName = '';
    developerDirectors: Director[] = [];
    occupancyCertificateDate?: Date;
}

export interface IAddNewBuildingPostModel {
    buildingId?: string;
    buildingName: string;
    about: string;
    title: string;
    postcode: string;
    cityName: string;
    streetName: string;
    streetNumber: string;
    stateName: string;
    countryName: string;
    address: string;
    builder?: string;
    propertyOwner?: string;
    latitude?: number;
    longitude?: number;
    siteArea?: number;
    floorArea?: number;
    riseInStoreys?: number;
    effectiveHeight?: number;
    maxOccupants: string;
    noOfSoleOccupancyUnits: string;
    developerName: string;
    developerDirectors: IDirector[];
    addressId?: string;
    occupancyCertificateDate?: Date;
    creationStatus: BuildingManualCreationStatus;
}

export class BuildingDTO implements IAddNewBuildingPostModel {
    buildingId?: string;
    buildingName = '';
    about = '';
    title = '';
    postcode = '';
    cityName = '';
    streetName = '';
    streetNumber = '';
    stateName = '';
    state!: number;
    countryName = '';
    address!: string;
    propertyOwner!: any;
    builder!: any;
    buildingStatusId!: BuildingStatus;
    classId: Array<string> = [''];
    latitude = 0;
    longitude = 0;

    siteArea!: number;
    floorArea!: number;
    riseInStoreys!: number;
    effectiveHeight!: number;
    maxOccupants!: string;
    noOfSoleOccupancyUnits!: string;
    classes = '';

    developerName = '';
    developerDirectors: Director[] = [];
    keyDocuments: KeyDocument[] = [];
    developerDirectorNames = '';
    occupancyCertificateDate?: Date;
    addressId?: string;
    creationStatus: BuildingManualCreationStatus =
        BuildingManualCreationStatus.Draft;
}

export interface IOCDetailsExtraction {
    title?: string;
    buildingClasses?: Array<string>;
    riseInStoreys?: number;
    effectiveHeight?: number;
    ocIssueDate?: Date;
    about?: Array<string>;
    address?: string;
    maxOccupants?: string;
}

export interface IDirector {
    name?: string;
}

export class Director {
    name = '';
}

export class KeyDocument {
    documentId!: string;
    documentName!: string;
    keyDocumentType!: number;
}

export class Contact {
    id!: string | null;
    contactName!: string;
    companyName!: string;
    email!: string;
    phone!: string;
    contactType!: null | number;
    buildingSurveyorRegistrationNumber!: string;
    constructor() {
        this.id = null;
        this.contactName = '';
        this.companyName = '';
        this.email = '';
        this.phone = '';
        this.buildingSurveyorRegistrationNumber = '';
        this.contactType = null;
    }
}

export class imgModel {
    id!: string;
    fileBase64String!: string;
    fileExtension!: string;
}

export class ClassModel {
    classGuid!: string;
    constructionType!: string;
    description!: string;
    id!: number;
    name!: string;
    checked!: boolean;
}
