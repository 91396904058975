import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ApiServiceBase } from '../api-service-base';
import { GetNotificationsRequestDTO } from '../models/notification.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationApiService extends ApiServiceBase {
    private apiNotificationBase = `${this.appConfig.apiBase}/api/notifications`;

    getNotificationsCount() {
        const url = `${this.apiNotificationBase}/recent`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getNotifications(postBody: GetNotificationsRequestDTO) {
        const url = `${this.apiNotificationBase}/all`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    readAll() {
        const url = `${this.apiNotificationBase}/read-all`;
        return this.http.post<any>(url, null).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    delete(id: number) {
        const url = `${this.apiNotificationBase}/${id}/delete`;
        return this.http.post<any>(url, id).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }
}
