export class RegexHelper {
    static isEmail(search: string) {
        const emailRegexp = new RegExp(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        );
        const isEmailValid = emailRegexp.test(search);
        return isEmailValid;
    }

    static isPhone(search: string) {
        if (search === '' || search == null) return true;
        const regexp = new RegExp('[- +()0-9]{10,12}');
        const serchfind = regexp.test(search);
        return serchfind;
    }

    static isWebsite(search: string) {
        const regexp = new RegExp(
            /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*$/,
            'i' // Add 'i' flag for case-insensitive matching
        );
        const serchfind = regexp.test(search);
        return serchfind;
    }

    /**
     * Converts uppercase text to normal case where each word starts with a capital letter
     * and the rest of the letters are in lowercase.
     *
     * @param text - The uppercase text to be converted.
     * @returns The text converted to normal case.
     */
    static convertToNormalCase(text: string | undefined | null): string {
        if (!text) return '';

        return text
            .split(' ')
            .map(
                (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' ');
    }
}
