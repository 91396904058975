// file.service.ts
import { Injectable, inject } from '@angular/core';
import { FileHelper } from '@quipex/shared/helpers';

import { ConfirmationType } from '@quipex/shared/components';
import { environment } from '../../../../../../src/environments/environment';
import { DialogService } from './dialog.service';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    private dialogService = inject(DialogService);

    validateFiles(files: File[]): boolean {
        if (this.validateFileSize(files) && this.validateFileType(files)) {
            return true;
        }
        return false;
    }

    private validateFileSize(files: File[]): boolean {
        let totalSize = 0;

        for (const file of files) {
            totalSize += file.size;
        }

        const sizeInMb = this.bytesToMegaBytes(totalSize);
        if (sizeInMb > environment.maxFileSizeInMb) {
            this.dialogService.openConfirmationDialog(
                'File size is too big',
                `File must not be greater than ${environment.maxFileSizeInMb}MB`,
                '',
                ConfirmationType.Error,
                ConfirmationType.Default,
                'OK',
                '',
                false,
                false
            );
            return false;
        }
        return true;
    }

    private validateFileType(files: File[]): boolean {
        const file = files[0];
        if (!FileHelper.checkFileUploadType(file.name)) {
            this.dialogService.openConfirmationDialog(
                'File type is not allowed',
                `File must be of type ${environment.documentUploadTypes.join(', ')}`,
                '',
                ConfirmationType.Error,
                ConfirmationType.Default,
                'OK',
                '',
                false,
                false
            );
            return false;
        }
        return true;
    }

    private bytesToMegaBytes(bytes: number) {
        return bytes / (1024 * 1024);
    }
}
