<div>
    <div class="title">Building Alerts</div>

    @if (isLoading()) {
        <qpx-loader></qpx-loader>
    } @else {
        @if (dataSource(); as dataSource) {
            @if (dataSource.length) {
                <div
                    class="table-wrapper qpx-thin-scrollbar"
                    [ngClass]="{ expanded: expanded() }"
                    infiniteScroll
                    [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="500"
                    (scrolled)="onScroll()"
                    [scrollWindow]="false"
                >
                    <table
                        mat-table
                        class="bottom-border padded"
                        [dataSource]="dataSource"
                    >
                        <ng-container matColumnDef="building">
                            <th
                                mat-header-cell
                                mat-sort-header
                                *matHeaderCellDef
                            >
                                Building
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                (click)="
                                    navigateToBuildingOverview(
                                        element.isAccessible,
                                        element.buildingId
                                    )
                                "
                            >
                                <div class="cursor-pointer">
                                    {{
                                        element.buildingAddress
                                            | qpxAddress: 'street'
                                    }}
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="fire">
                            <th mat-header-cell *matHeaderCellDef>
                                {{
                                    esmCategoryNamesEnum.FIRE_PROTECTION_SYSTEMS_EQUIPMENT
                                }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div
                                    class="status"
                                    (click)="
                                        navigateToEsm(
                                            element.isAccessible,
                                            element.fire.categoryId,
                                            element.buildingId,
                                            esmCategoryNamesEnum.FIRE_PROTECTION_SYSTEMS_EQUIPMENT
                                        )
                                    "
                                >
                                    @if (element.fire.isCompliant) {
                                        <div class="status__item">
                                            <mat-icon>check_circle</mat-icon>
                                            <span>Compliant</span>
                                        </div>
                                    } @else {
                                        @if (element.fire.criticalCount) {
                                            <div class="status__item">
                                                <mat-icon class="critical"
                                                    >error</mat-icon
                                                >
                                                <span
                                                    >{{
                                                        element.fire
                                                            .criticalCount
                                                    }}
                                                    Critical</span
                                                >
                                            </div>
                                        }
                                        @if (element.fire.overdueCount) {
                                            <div class="status__item">
                                                <mat-icon class="overdue"
                                                    >error</mat-icon
                                                >
                                                <span
                                                    >{{
                                                        element.fire
                                                            .overdueCount
                                                    }}
                                                    Overdue</span
                                                >
                                            </div>
                                        }

                                        @if (
                                            !element.fire.criticalCount &&
                                            !element.fire.overdueCount
                                        ) {
                                            <span>N/A</span>
                                        }
                                    }
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="lights">
                            <th mat-header-cell *matHeaderCellDef>
                                {{
                                    esmCategoryNamesEnum.EMERGENCY_LIGHTING_EXIT_SIGNS
                                }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div
                                    class="status"
                                    (click)="
                                        navigateToEsm(
                                            element.isAccessible,
                                            element.lights.categoryId,
                                            element.buildingId,
                                            esmCategoryNamesEnum.EMERGENCY_LIGHTING_EXIT_SIGNS
                                        )
                                    "
                                >
                                    @if (element.lights.isCompliant) {
                                        <div class="status__item">
                                            <mat-icon>check_circle</mat-icon>
                                            <span>Compliant</span>
                                        </div>
                                    } @else {
                                        @if (element.lights.criticalCount) {
                                            <div class="status__item">
                                                <mat-icon class="critical"
                                                    >error</mat-icon
                                                >
                                                <span
                                                    >{{
                                                        element.lights
                                                            .criticalCount
                                                    }}
                                                    Critical</span
                                                >
                                            </div>
                                        }
                                        @if (element.lights.overdueCount) {
                                            <div class="status__item">
                                                <mat-icon class="overdue"
                                                    >error</mat-icon
                                                >
                                                <span
                                                    >{{
                                                        element.lights
                                                            .overdueCount
                                                    }}
                                                    Overdue</span
                                                >
                                            </div>
                                        }

                                        @if (
                                            !element.lights.criticalCount &&
                                            !element.lights.overdueCount
                                        ) {
                                            <span>N/A</span>
                                        }
                                    }
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="air">
                            <th mat-header-cell *matHeaderCellDef>
                                {{
                                    esmCategoryNamesEnum.AIR_HANDLING_WATER_SYSTEMS
                                }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div
                                    class="status"
                                    (click)="
                                        navigateToEsm(
                                            element.isAccessible,
                                            element.air.categoryId,
                                            element.buildingId,
                                            esmCategoryNamesEnum.AIR_HANDLING_WATER_SYSTEMS
                                        )
                                    "
                                >
                                    @if (element.air.isCompliant) {
                                        <div class="status__item">
                                            <mat-icon>check_circle</mat-icon>
                                            <span>Compliant</span>
                                        </div>
                                    } @else {
                                        @if (element.air.criticalCount) {
                                            <div class="status__item">
                                                <mat-icon class="critical"
                                                    >error</mat-icon
                                                >
                                                <span
                                                    >{{
                                                        element.air
                                                            .criticalCount
                                                    }}
                                                    Critical</span
                                                >
                                            </div>
                                        }
                                        @if (element.air.overdueCount) {
                                            <div class="status__item">
                                                <mat-icon class="overdue"
                                                    >error</mat-icon
                                                >
                                                <span
                                                    >{{
                                                        element.air.overdueCount
                                                    }}
                                                    Overdue</span
                                                >
                                            </div>
                                        }

                                        @if (
                                            !element.air.criticalCount &&
                                            !element.air.overdueCount
                                        ) {
                                            <span>N/A</span>
                                        }
                                    }
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="custom">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ esmCategoryNamesEnum.CUSTOM }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div
                                    class="status"
                                    (click)="
                                        navigateToEsm(
                                            element.isAccessible,
                                            element.custom.categoryId,
                                            element.buildingId,
                                            esmCategoryNamesEnum.AIR_HANDLING_WATER_SYSTEMS
                                        )
                                    "
                                >
                                    @if (element.custom.isCompliant) {
                                        <div class="status__item">
                                            <mat-icon>check_circle</mat-icon>
                                            <span>Compliant</span>
                                        </div>
                                    } @else {
                                        @if (element.custom.criticalCount) {
                                            <div class="status__item">
                                                <mat-icon class="critical"
                                                    >error</mat-icon
                                                >
                                                <span
                                                    >{{
                                                        element.custom
                                                            .criticalCount
                                                    }}
                                                    Critical</span
                                                >
                                            </div>
                                        }
                                        @if (element.custom.overdueCount) {
                                            <div class="status__item">
                                                <mat-icon class="overdue"
                                                    >error</mat-icon
                                                >
                                                <span
                                                    >{{
                                                        element.air.overdueCount
                                                    }}
                                                    Overdue</span
                                                >
                                            </div>
                                        }

                                        @if (
                                            !element.custom.criticalCount &&
                                            !element.custom.overdueCount
                                        ) {
                                            <span>N/A</span>
                                        }
                                    }
                                </div>
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns; sticky: true"
                        ></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns"
                        ></tr>
                    </table>
                </div>
            } @else {
                <div class="empty-result">Looking good! No building alerts</div>
            }
        }
    }
</div>
