export interface IAssistantPostModel {
    userInput: string;
    gptModel: GPTModel;
    instructions?: string;
    conversationHistory?: IConversationMessage[];
}

export interface IConversationMessage {
    role: 'user' | 'assistant';
    content: string;
}

export enum GPTModel {
    GPT3_TURBO = 'gpt-35-turbo',
    GPT4 = 'gpt-4-1106',
    GPT40 = 'gpt-4o',
}
