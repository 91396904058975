import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

export enum ConfirmationType {
    Success = 'Success',
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error',
    Default = 'Default',
}
export interface IConfirmationDialogData {
    title: string;
    text1?: string;
    text2?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    confirmButtonType?: ConfirmationType;
    type?: ConfirmationType;
    showCloseIcon?: boolean;
    cancelButtonVisible?: boolean;
}
@Component({
    selector: 'qpx-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule],
})
export class ConfirmationDialogComponent implements OnInit {
    confirmationType = ConfirmationType;
    confirmButtonColor!: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogData
    ) {}

    ngOnInit(): void {
        this.getConfirmButtonColor();
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    getConfirmButtonColor(): void {
        switch (this.data.confirmButtonType) {
            case ConfirmationType.Info: {
                this.confirmButtonColor = 'primary';
                break;
            }
            case ConfirmationType.Warning:
            case ConfirmationType.Error: {
                this.confirmButtonColor = 'warn';
                break;
            }
            default: {
                this.confirmButtonColor = 'accent';
                break;
            }
        }
    }
}
