import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';

import { ApiServiceBase } from '../api-service-base';
import {
    BHCOverallScoreCard,
    BhcRatingBreakdownModel,
    BhcScoreOnDateModel,
} from '../models';
import { IGetBhcIntegrityScoreResponse } from '../models/typings/bhc.interface';

@Injectable({
    providedIn: 'root',
})
export class BhcApiService extends ApiServiceBase {
    private apiBhcBase = `${this.appConfig.apiBase}/api/bhcs`;

    getOverallScore(buildingId: number | undefined) {
        const url = `${this.apiBhcBase}/integrity-score/${buildingId ?? ''}`;
        return this.http.get<any>(url).pipe(
            map((response) => response as BHCOverallScoreCard),
            catchError(this.handleError)
        );
    }

    getOverallCultureScore(buildingId: number | undefined) {
        const url = `${this.apiBhcBase}/culture-score/${buildingId ?? ''}`;
        return this.http.get<any>(url).pipe(
            map((response) => response as BHCOverallScoreCard),
            catchError(this.handleError)
        );
    }

    getIntegrityScore(
        buildingId: number | undefined
    ): Observable<IGetBhcIntegrityScoreResponse> {
        const url = `${this.apiBhcBase}/understand-integrity-score?buildingId=${buildingId ?? ''}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getRatingBreakdown() {
        const url = `${this.apiBhcBase}/score-card/breakdown`;
        return this.http.get<any>(url).pipe(
            map((response) => response as BhcRatingBreakdownModel[]),
            catchError(this.handleError)
        );
    }

    getIntervalScores(postBody: any) {
        const url = `${this.apiBhcBase}/score-vs-time/${postBody.bhcScoreIntervalType
            .valueOf()
            .toString()}/${postBody.buildingId ?? ''}`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response as BhcScoreOnDateModel[]),
            catchError(this.handleError)
        );
    }
}
