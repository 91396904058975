import { FilterModel } from '../../../../../../shared/data/src';

export enum AssetCategoryEnum {
    Operational = 1,
    Ancillary,
    NonOperational,
    Administered,
}

export enum AssetCategoryNamesEnum {
    Operational = 'Operational',
    Ancillary = 'Ancillary',
    NonOperational = 'Non-operational',
    Administered = 'Administered',
}

export const assetCategoryOptions: {
    id: AssetCategoryEnum;
    name: AssetCategoryNamesEnum;
}[] = [
    {
        id: AssetCategoryEnum.Operational,
        name: AssetCategoryNamesEnum.Operational,
    },
    { id: AssetCategoryEnum.Ancillary, name: AssetCategoryNamesEnum.Ancillary },
    {
        id: AssetCategoryEnum.NonOperational,
        name: AssetCategoryNamesEnum.NonOperational,
    },
    {
        id: AssetCategoryEnum.Administered,
        name: AssetCategoryNamesEnum.Administered,
    },
];

export enum AssetCriticalityEnum {
    NoImpact = 1,
    Low,
    Medium,
    High,
    Critical,
}

export enum AssetCriticalityNamesEnum {
    NoImpact = 'No impact',
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Critical = 'Critical',
}

export const assetCriticalityOptions: {
    id: AssetCriticalityEnum;
    name: AssetCriticalityNamesEnum;
}[] = [
    {
        id: AssetCriticalityEnum.NoImpact,
        name: AssetCriticalityNamesEnum.NoImpact,
    },
    { id: AssetCriticalityEnum.Low, name: AssetCriticalityNamesEnum.Low },
    { id: AssetCriticalityEnum.Medium, name: AssetCriticalityNamesEnum.Medium },
    { id: AssetCriticalityEnum.High, name: AssetCriticalityNamesEnum.High },
    {
        id: AssetCriticalityEnum.Critical,
        name: AssetCriticalityNamesEnum.Critical,
    },
];

export enum AssetConditionEnum {
    Failed = 1,
    BadlyDeteriorated,
    AverageCondition,
    MinorDefects,
    NoDefects,
}

export enum AssetConditionNamesEnum {
    Failed = 'Failed',
    BadlyDeteriorated = 'Badly deteriorated',
    AverageCondition = 'Average condition',
    MinorDefects = 'Minor defects',
    NoDefects = 'No defects',
}

export const assetConditionOptions: {
    id: AssetConditionEnum;
    name: AssetConditionNamesEnum;
}[] = [
    { id: AssetConditionEnum.Failed, name: AssetConditionNamesEnum.Failed },
    {
        id: AssetConditionEnum.BadlyDeteriorated,
        name: AssetConditionNamesEnum.BadlyDeteriorated,
    },
    {
        id: AssetConditionEnum.AverageCondition,
        name: AssetConditionNamesEnum.AverageCondition,
    },
    {
        id: AssetConditionEnum.MinorDefects,
        name: AssetConditionNamesEnum.MinorDefects,
    },
    {
        id: AssetConditionEnum.NoDefects,
        name: AssetConditionNamesEnum.NoDefects,
    },
];

export enum AssetRegisterReportingYearsEnum {
    CALENDAR = 1,
    FINANCIAL = 2,
}

export enum AssetRegisterReportingYearEnum {
    CALENDAR = 'Calendar',
    FINANCIAL = 'Financial',
}

export const reportingYearOptions: {
    id: AssetRegisterReportingYearsEnum;
    name: AssetRegisterReportingYearEnum;
}[] = [
    {
        id: AssetRegisterReportingYearsEnum.CALENDAR,
        name: AssetRegisterReportingYearEnum.CALENDAR,
    },
    {
        id: AssetRegisterReportingYearsEnum.FINANCIAL,
        name: AssetRegisterReportingYearEnum.FINANCIAL,
    },
];

export interface IGeAssetRegisterResponse {
    reportingYear: AssetRegisterReportingYearsEnum;
    assetDetails: IGeAssetRegister;
}

export enum CostOfReplacementEnum {
    CostLessThan1000 = 1,
    CostLessThan5000 = 2,
    CostMoreThan5000 = 3,
}

export enum CostOfReplacementNamesEnum {
    CostLessThan1000 = '$0-999',
    CostLessThan5000 = '$1000-4999',
    CostMoreThan5000 = '$5000+',
}

export enum AssetRegisterFilterEnum {
    CostOfReplacement = 'Estimated cost of replacement',
    Condition = 'Condition',
    EndOfLife = 'End of life',
}

export enum AssetEndOfLifeNamesEnum {
    NextMonth = 'Next month',
    NextThreeMonths = 'Next 3 months',
    NextSixMonths = 'Next 6 months',
    NextTwelveMonths = 'Next 12 months',
}

export enum AssetEndOfLifeEnum {
    NextMonth = 1,
    NextThreeMonths = 2,
    NextSixMonths = 3,
    NextTwelveMonths = 4,
}

export const filters: Array<FilterModel> = [
    {
        name: AssetRegisterFilterEnum.CostOfReplacement,
        array: [
            { id: 0, name: 'All' },
            {
                id: CostOfReplacementEnum.CostLessThan1000.toString(),
                name: CostOfReplacementNamesEnum.CostLessThan1000,
            },
            {
                id: CostOfReplacementEnum.CostLessThan5000.toString(),
                name: CostOfReplacementNamesEnum.CostLessThan5000,
            },
            {
                id: CostOfReplacementEnum.CostMoreThan5000.toString(),
                name: CostOfReplacementNamesEnum.CostMoreThan5000,
            },
        ],
    },
    {
        name: AssetRegisterFilterEnum.Condition,
        array: [
            { id: 0, name: 'All' },
            {
                id: AssetConditionEnum.AverageCondition.toString(),
                name: AssetConditionNamesEnum.AverageCondition,
            },
            {
                id: AssetConditionEnum.BadlyDeteriorated.toString(),
                name: AssetConditionNamesEnum.BadlyDeteriorated,
            },
            {
                id: AssetConditionEnum.Failed.toString(),
                name: AssetConditionNamesEnum.Failed,
            },
            {
                id: AssetConditionEnum.MinorDefects.toString(),
                name: AssetConditionNamesEnum.MinorDefects,
            },
            {
                id: AssetConditionEnum.NoDefects.toString(),
                name: AssetConditionNamesEnum.NoDefects,
            },
        ],
    },
    {
        name: AssetRegisterFilterEnum.EndOfLife,
        array: [
            { id: 0, name: 'All' },
            {
                id: AssetEndOfLifeEnum.NextMonth.toString(),
                name: AssetEndOfLifeNamesEnum.NextMonth,
            },
            {
                id: AssetEndOfLifeEnum.NextThreeMonths.toString(),
                name: AssetEndOfLifeNamesEnum.NextThreeMonths,
            },
            {
                id: AssetEndOfLifeEnum.NextSixMonths.toString(),
                name: AssetEndOfLifeNamesEnum.NextSixMonths,
            },
            {
                id: AssetEndOfLifeEnum.NextTwelveMonths.toString(),
                name: AssetEndOfLifeNamesEnum.NextTwelveMonths,
            },
        ],
    },
];

export interface IGeAssetRegister {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    pageIndex: number;
    totalCount: number;
    totalPages: number;
    items: IAssetRegister[];
}

export interface IAssetRegister {
    id: string;
    name: string;
    category: AssetCategoryEnum;
    criticality: AssetCriticalityEnum;
    condition: AssetConditionEnum;
    endOfLifeDate: Date;
    expectedReplacementCost: number;
    categoryName: AssetCategoryNamesEnum | string; // UI only
    criticalityName: AssetCriticalityNamesEnum | string; // UI only
    conditionName?: AssetConditionNamesEnum | string; // UI only
    showDateAlert: boolean; // UI only
}

export interface IAssetRegistersDetails {
    id: string;
    name: string;
    category: AssetCategoryEnum;
    criticality: AssetCriticalityEnum;
    condition: AssetConditionEnum;
    endOfLifeDate: Date;
    description: string;
    manufacturer: string;
    model: string;
    serialNo: string;
    warranty: string;
    dateOfPurchase: Date;
    costOfPurchase: number;
    location: string;
    expectedReplacementCost: number;
    documents: IAssetRegistersDocument[];
}

export interface IAssetRegistersDocument {
    documentId: string;
    documentName: string;
}

export interface IAssetRegisterPostModel {
    buildingId: string;
    name: string;
    category: AssetCategoryEnum;
    criticality: AssetCriticalityEnum;
    condition: AssetConditionEnum;
    endOfLifeDate: Date | null;
    description: string;
    manufacturer: string;
    model: string;
    serialNo: string;
    warranty: string;
    dateOfPurchase: Date | null;
    costOfPurchase: number;
    location: string;
    expectedReplacementCost: number;
    documents: File[];
}

export interface IAssetRegisterPutModel {
    assetId: string;
    name: string;
    category: AssetCategoryEnum;
    criticality: AssetCriticalityEnum;
    condition: AssetConditionEnum;
    endOfLifeDate: Date | null;
    description: string;
    manufacturer: string;
    model: string;
    serialNo: string;
    warranty: string;
    dateOfPurchase: Date | null;
    costOfPurchase: number;
    location: string;
    expectedReplacementCost: number;
    existingDocumentIds: number[];
    newDocuments: File[];
}
