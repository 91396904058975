@if (isLoading()) {
    <qpx-loader></qpx-loader>
}

@if (form) {
    <h2 mat-dialog-title>
        <span class="flex flex-grow">Review building details</span>
    </h2>

    <mat-dialog-content>
        <div>
            We've identified some building details in the document(s) you are
            uploading that differ from details currently held on file
        </div>
        <div class="review-info-text">
            Review the updates below and accept or reject to ensure your
            building manual remains accurate and up to date
        </div>

        <form [formGroup]="form">
            <div class="labels">
                <div class="labels__current">Current</div>
                <div class="labels__new">New</div>
                <div class="labels__actions">
                    <div class="flex">
                        <span class="flex flex-grow justify-center"
                            >Accept</span
                        >
                        <span class="flex flex-grow justify-center"
                            >Reject</span
                        >
                    </div>

                    <mat-radio-group
                        formControlName="acceptAll"
                        (change)="onAcceptAllChange()"
                    >
                        <mat-radio-button
                            [disableRipple]="true"
                            color="primary"
                            [value]="true"
                            labelPosition="before"
                        >
                            Accept all
                        </mat-radio-button>
                        <mat-radio-button
                            [disableRipple]="true"
                            color="warn"
                            [value]="false"
                            labelPosition="before"
                        >
                            Reject all
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <ng-container
                *ngFor="
                    let itemCtrlGroup of $any(itemsCtrl?.controls);
                    let i = index
                "
            >
                <div
                    class="flex align-center form-group"
                    [formGroup]="itemCtrlGroup"
                >
                    <div class="flex flex-grow flex-col">
                        <mat-label>{{
                            itemCtrlGroup.controls.label.value
                        }}</mat-label>
                        <mat-form-field class="disabled-dark">
                            <input matInput formControlName="oldValue" />
                        </mat-form-field>
                    </div>
                    <div class="flex flex-grow flex-col">
                        <mat-label>{{
                            itemCtrlGroup.controls.label.value
                        }}</mat-label>
                        <mat-form-field class="disabled-dark">
                            <input matInput formControlName="newValue" />
                        </mat-form-field>
                    </div>

                    <div class="radio-controls">
                        <mat-radio-group
                            class="flex"
                            formControlName="radioValue"
                        >
                            <mat-radio-button
                                class="flex flex-grow justify-center"
                                color="primary"
                                [disableRipple]="true"
                                [value]="true"
                            >
                            </mat-radio-button>
                            <mat-radio-button
                                class="flex flex-grow justify-center"
                                color="warn"
                                [disableRipple]="true"
                                [value]="false"
                            >
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </ng-container>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button
            mat-stroked-button
            [color]="'primary'"
            [disabled]="!form.valid"
            (click)="onSaveClicked()"
        >
            <mat-icon class="qpx-icon-sm">check</mat-icon>Save
        </button>
    </mat-dialog-actions>
}
