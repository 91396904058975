<qpx-bulk-upload-files
    #bulkUploadFiles
    [showBuildingReviewScreen]="showBuildingReviewScreen"
    [buildingId]="buildingId"
    [isUploading]="isProcessing"
    (uploadFinished)="onUploadFinished()"
    (isProcessingChange)="onIsProcessingChange($event)"
    (canConfirmChange)="onCanConfirmChange($event)"
>
</qpx-bulk-upload-files>
