import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    input,
    signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import {
    EsmApiService,
    EsmCategoryEnum,
    EsmCategoryNamesEnum,
    esmCategoryyOptions,
    IGetESMDataPointsByCategoryResponse,
} from '@quipex/shared/data';
import { finalize } from 'rxjs';
import { LoaderComponent } from '../loader.component';

@Component({
    selector: 'qpx-building-health-widget',
    templateUrl: './building-health-widget.component.html',
    styleUrls: ['./building-health-widget.component.scss'],
    imports: [CommonModule, MatIconModule, LoaderComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingHealthWidgetComponent {
    @Input() set buildingId(guid: string) {
        this._buildingId = guid;
        this.getESMDataPointsByCategory();
    }

    canNavigateToMaintenance = input<boolean>(false);
    isLoading = signal(false);
    responseIsEmpty = signal(false);
    categories: IGetESMDataPointsByCategoryResponse[] = [];

    private _buildingId!: string;

    private readonly router = inject(Router);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly esmService = inject(EsmApiService);

    navigateToEsm(category: EsmCategoryEnum): void {
        if (this.canNavigateToMaintenance()) {
            this.router.navigateByUrl(
                `buildings/${this._buildingId}/maintenance`,
                {
                    state: {
                        category,
                    },
                }
            );
        }
    }

    getCategoryLabel(category: EsmCategoryEnum): EsmCategoryNamesEnum | null {
        return (
            esmCategoryyOptions.find((option) => option.id === category)
                ?.name ?? null
        );
    }

    private getESMDataPointsByCategory(): void {
        this.isLoading.set(true);
        this.esmService
            .getESMDataPointsByCategory(this._buildingId)
            .pipe(finalize(() => this.isLoading.set(false)))
            .subscribe({
                next: (response) => {
                    if (!response?.length) {
                        this.responseIsEmpty.set(true);
                    } else {
                        this.categories = response;
                        this.cdr.markForCheck();
                    }
                },
            });
    }
}
