import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, map } from 'rxjs';
import { ISearchDocsRequest } from '../models/typings/doc-search/search-documents-request.interface';
import {
    ISearchBuildingDocsResponse,
    ISearchOrgDocsResponse,
} from '../models/typings/doc-search/search-documents-reponse.interface';
import { ApiServiceBase } from '../api-service-base';

@Injectable({
    providedIn: 'root',
})
export class AzureFunctionApiService extends ApiServiceBase {
    private apiUrl = environment.apiFunctionBase;

    runSearchIndexer() {
        const url = `${this.apiUrl}/RunIndexer`;
        return this.http.get<any>(url);
    }

    searchBuildingDocs(
        postBody: ISearchDocsRequest
    ): Observable<ISearchBuildingDocsResponse> {
        const url = `${this.apiUrl}/search-building-docs`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response.Value as ISearchBuildingDocsResponse),
            catchError(this.handleError)
        );
    }

    searchPortfolioDocs(
        postBody: ISearchDocsRequest
    ): Observable<ISearchOrgDocsResponse> {
        const url = `${this.apiUrl}/search-org-docs`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response.Value as ISearchOrgDocsResponse),
            catchError(this.handleError)
        );
    }
}
