<div class="building-search">
    <div class="search-navbar" (click)="toggleDropdown()">
        <mat-icon>search</mat-icon>
        <span class="search-navbar__text">Find a building</span>
    </div>

    <div
        class="building-dropdown"
        [ngClass]="{ expanded: filteredBuildingNames$.value.length > 0 }"
        *ngIf="isDropdownOpen"
    >
        <div class="header">
            <span>Find a building</span>
            <span class="close-icon" (click)="toggleDropdown()">
                <mat-icon>close</mat-icon>
            </span>
        </div>

        <div class="search-input-container">
            <form [formGroup]="form">
                <mat-form-field class="w-100 no-error focused-primary">
                    <mat-icon matPrefix>search</mat-icon>
                    <input
                        #searchInputEl
                        #trigger="matAutocompleteTrigger"
                        type="text"
                        name="buildingName"
                        data-testid="buildingNameLink"
                        spellcheck="false"
                        autocomplete="off"
                        placeholder="Search building address"
                        matInput
                        maxlength="50"
                        #buildingNameSearchEl
                        [matAutocomplete]="buildingNameSearch"
                        [formControl]="buildingNameSearchCtrl"
                    />
                    <mat-icon
                        matSuffix
                        class="cursor-pointer"
                        (click)="clearSearch($event)"
                        >close</mat-icon
                    >
                    <qpx-loader *ngIf="isLoading"></qpx-loader>

                    <mat-autocomplete
                        autoActiveFirstOption
                        #buildingNameSearch="matAutocomplete"
                        class="building-search-autocomplete"
                        (optionSelected)="onBuildingNameSelected($event)"
                    >
                        <ng-container
                            *ngFor="
                                let result of filteredBuildingNames$
                                    | async as searchResult
                            "
                        >
                            <mat-option
                                class="result-mat-option"
                                [value]="result"
                                [disabled]="result.buildingGuid === '0'"
                                (click)="navigateToDetails(result.buildingGuid)"
                            >
                                <ng-container
                                    *ngIf="result.buildingGuid !== '0'"
                                >
                                    <div class="title">
                                        {{ result | qpxAddress: 'street' }}
                                    </div>
                                    <div class="subtitle">
                                        {{ result | qpxAddress: 'city' }}
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="result.buildingGuid === '0'"
                                >
                                    <div class="no-results">No Match Found</div>
                                </ng-container>

                                <ng-container
                                    *ngIf="result.buildingGuid === '0'"
                                >
                                    <div class="no-results">No Match Found</div>
                                </ng-container>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </mat-form-field>
            </form>
        </div>
    </div>
</div>
