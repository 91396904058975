import { NgTemplateOutlet } from '@angular/common';
import {
    Component,
    DestroyRef,
    inject,
    input,
    Input,
    OnInit,
    output,
    signal,
    TemplateRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ReviewBuildingDetailsComponent } from '../../review-building-details/review-building-details.component';
import {
    AzureFunctionApiService,
    BuildingDocuments,
    ClassifyDocumentResponse,
    DocDto,
    DocumentApiService,
    DocumentTypeEnum,
    IOCDetailsExtraction,
    SharedService,
    UserStore,
} from '@quipex/shared/data';
import { FileHelper } from '@quipex/shared/helpers';
import { finalize } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { FileListComponent } from '../file-list/file-list.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { DocumentLoaderComponent } from '../upload-files/document-loading-screen/document-loading-screen';

@Component({
    selector: 'qpx-bulk-upload-files',
    templateUrl: './bulk-upload-files.component.html',
    styleUrls: ['./bulk-upload-files.component.scss'],
    imports: [
        NgTemplateOutlet,
        MatFormFieldModule,
        MatChipsModule,
        MatDialogModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,
        DocumentLoaderComponent,
        FileUploadComponent,
        FileListComponent,
    ],
})
export class BulkUploadFilesComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    private readonly sharedService = inject(SharedService);
    private readonly documentService = inject(DocumentApiService);
    private readonly azFunctionService = inject(AzureFunctionApiService);
    private readonly userStore = inject(UserStore);
    private readonly snackBar = inject(MatSnackBar);
    private readonly dialog = inject(MatDialog);

    @Input() buildingId!: string;
    @Input() isUploading = false;
    @Input() files: DocDto[] = [];

    public readonly viewType = input<
        'upload-with-file-list' | 'upload-with-tags' | 'tags-only'
    >('upload-with-tags');
    public readonly shouldCheckForDuplicates = input(true);
    /** template for loading state */
    public readonly loadingTpl = input<TemplateRef<any> | null>(null);
    public readonly showBuildingReviewScreen = input(false);
    /** allow classification of documents */
    public readonly canClassifyDocuments = input(true);

    public readonly filesSelectionChange = output<DocDto[]>();
    public readonly uploadFinished = output<void>();
    public readonly isProcessingChange = output<boolean>();
    public readonly canConfirmChange = output<boolean>();

    protected readonly isLoaded = signal(true);

    private _currentUserId!: number;
    private readonly _processedFiles: Set<string> = new Set();

    ngOnInit(): void {
        // Subscribe to route parameters
        this.sharedService.queryParams$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((params) => {
                if (params) {
                    this.buildingId = params;
                }
            });

        this.getUserInfo();
    }

    onFileRemove(fileId: string): void {
        this.removeFile(fileId);
        this.filesSelectionChange.emit(this.files);
    }

    fileProcessingChange(processing: boolean): void {
        this.isLoaded.set(!processing);
    }

    onFileUploadListChanged(files: File[]): void {
        if (files?.length) {
            this.prepareFilesList(files);
        } else {
            this.isLoaded.set(true);
        }
    }

    onFileListChange(): void {
        // files object has been altered inside file-list component
        this.filesSelectionChange.emit(this.files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    async handleUploadDocuments() {
        this.isUploading = true;
        this.isProcessingChange.emit(true);
        const documents = await this.documentService.prepareDocumentsForUpload(
            this.files,
            this.buildingId,
            this._currentUserId
        );
        this.uploadDocuments(documents);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    private prepareFilesList(files: File[]) {
        this.isLoaded.set(false);
        const fileArray: File[] = Array.from(files);
        this.canConfirmChange.emit(false);
        for (const item of fileArray) {
            const doc: DocDto = {
                id: uuidv4(),
                autoTag: [],
                isKeyDocument: false,
                file: item,
                documentType: null,
            };
            this.files.push(doc);
        }

        const newFiles = this.filterNewFiles(files);
        this.uploadAndClassifyDocuments(newFiles);
    }

    private uploadAndClassifyDocuments(fileList: File[]): void {
        this.isLoaded.set(false);
        // Remove files that aren't supported for auto tagging
        const files = fileList.filter((file) =>
            FileHelper.isFileAutoTagType(file.name)
        );

        if (!this.canClassifyDocuments() || !files.length) {
            this.canConfirmChange.emit(true);
            this.isLoaded.set(true);
        } else {
            this.canConfirmChange.emit(false);
            this.documentService.classifyDocuments(files).subscribe({
                next: (res: ClassifyDocumentResponse[]) => {
                    this.addDocumentTypeToFiles(res);
                    this.canConfirmChange.emit(true);
                    this.isLoaded.set(true);
                },
                error: () => {
                    this.canConfirmChange.emit(true);
                    this.isLoaded.set(true);
                },
            });
        }
    }

    private addDocumentTypeToFiles(response: ClassifyDocumentResponse[]) {
        this.files.forEach((file) => {
            const matchingDocument = response
                .filter((x) => x.fileName == file.file.name)
                .pop();

            if (matchingDocument) {
                // set a new property autoTag to be the matching tag from the response
                file.isKeyDocument = true;
                file.documentType = matchingDocument.documentType;
            }

            if (
                this.showBuildingReviewScreen() &&
                file.documentType === DocumentTypeEnum.OccupancyPermit
            ) {
                this.extractOCDetails(file);
            }
        });
        this.filesSelectionChange.emit(this.files);
        this.canConfirmChange.emit(true);
        this.isLoaded.set(true);
    }

    private extractOCDetails(file: DocDto): void {
        this.documentService.extractOCDetails(file.file).subscribe((res) => {
            // Only show the modal if all the properties are not empty or null
            if (
                Object.values(res).some(
                    (value) =>
                        value !== '' &&
                        value !== null &&
                        value !== undefined &&
                        !(Array.isArray(value) && value.length === 0)
                )
            ) {
                this.openReviewBuildingDetailsModal(res);
            }
        });
    }

    private uploadDocuments(documents: BuildingDocuments) {
        this.documentService
            .uploadDocument(documents)
            .pipe(finalize(() => this.isProcessingChange.emit(false)))
            .subscribe({
                next: () => {
                    this.azFunctionService
                        .runSearchIndexer()
                        .subscribe(() => undefined);
                    this.createToast(
                        'success',
                        'File(s) has been uploaded successfully!'
                    );
                    this.uploadFinished.emit();
                },
                error: (err) => {
                    this.createToast('danger', 'File(s) File upload failed!');
                },
                complete: () => {
                    // TODO: Anything to cleanup here?
                    this.files = [];
                },
            });
    }

    private createToast(panelClass: string, title: string): void {
        const icon = panelClass === 'success' ? 'check_circle' : 'error';
        this.snackBar.open(title, icon, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: panelClass,
            duration: 1500,
        });
    }

    private removeFile(id: string): void {
        const index = this.files.findIndex((file) => file.id === id);
        if (index !== -1) {
            this.files.splice(index, 1);
        }
    }

    private filterNewFiles(files: File[]): File[] {
        return files.filter((file) => {
            const fileId = this.generateFileId(file);
            if (!this._processedFiles.has(fileId)) {
                this._processedFiles.add(fileId);
                return true;
            }
            return false;
        });
    }

    private openReviewBuildingDetailsModal(
        ocDetailsExtraction: IOCDetailsExtraction
    ): void {
        this.dialog.open(ReviewBuildingDetailsComponent, {
            disableClose: true,
            panelClass: 'qpx-dialog',
            data: {
                buildingId: this.buildingId,
                ocDetailsExtraction: ocDetailsExtraction,
            },
        });
    }

    private generateFileId(file: File): string {
        return `${uuidv4()}_${file.name}`;
    }

    private getUserInfo(): void {
        this.userStore
            .select('me')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((user) => {
                this._currentUserId = user.internalId;
            });
    }
}
