<div class="header">
    <div class="header-content">
        <a routerLink="/dashboard" class="cursor-pointer">
            <img
                alt="Logo"
                src="./assets/media/quipex/quipex-text.png"
                class="header-logo"
            />
        </a>

        <div class="header-content__right">
            <div *ngIf="logoUrl" class="logo-wrapper">
                <img class="logo" [src]="logoUrl" />
            </div>
            <qpx-navbar></qpx-navbar>
        </div>
    </div>
</div>
