export class User {
    id!: string;
    identifier!: string;
    internalId!: number;
    name?: string;
    email?: string;
    isActive!: boolean;
    organizationId!: string;
    orgId!: number;
    createdAt!: Date;
    isDeleted?: boolean;
    lastUpdatedAt?: Date;
    lastLoggedIn?: Date;
    organizationName?: string;
    role!: string;
    roleId!: number;
    lastUpdatedBy?: string;
    personalPhone!: string;
    permissions!: Array<string>;
    orgCategory!: number;
}
