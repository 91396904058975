import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    OnInit,
    inject,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    FormControl,
    FormGroup,
    FormsModule,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderComponent } from '@quipex/shared/components';
import {
    AccessApiService,
    DialogService,
    IBuildingName,
    ICreateUser,
    IOrganisation,
    IRolesWithPermission,
    OrganizationApiService,
    PermissionApiService,
    User,
    UserApiService,
    UserStore,
} from '@quipex/shared/data';
import { HasRoleDirective } from '@quipex/shared/directives';
import { IBuildingSelect } from 'modules/feature/access/src/lib/access';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    forkJoin,
    of,
    switchMap,
} from 'rxjs';

export enum AddUserStepsEnum {
    Contact = 1,
    Buildings,
}

@Component({
    selector: 'qpx-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        LoaderComponent,
        HasRoleDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUserComponent implements OnInit {
    currentStep = signal<AddUserStepsEnum>(AddUserStepsEnum.Contact);
    isQuipexAdmin = signal(false);

    roles!: IRolesWithPermission[];
    organisations!: IOrganisation[];
    form!: FormGroup;
    buildingsAll: IBuildingSelect[] = [];
    filteredBuildings$ = new BehaviorSubject<IBuildingSelect[]>([]);

    addUserStepsEnum = AddUserStepsEnum;

    get buildingsSearchCtrl(): FormControl {
        return this.form.controls['buildingsSearch'] as FormControl;
    }

    get toggleCtrl(): FormControl {
        return this.form?.controls['toggle'] as FormControl;
    }

    private _currentUser!: User;

    private readonly destroyRef = inject(DestroyRef);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly formBuilder = inject(NonNullableFormBuilder);
    private readonly userStore = inject(UserStore);
    private readonly userService = inject(UserApiService);
    private readonly permissionService = inject(PermissionApiService);
    private readonly organizationService = inject(OrganizationApiService);
    private readonly accessService = inject(AccessApiService);
    private readonly dialogService = inject(DialogService);
    private readonly snackBar = inject(MatSnackBar);

    ngOnInit(): void {
        this.form = this.configureForm();
        this.userStore
            .select('me')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((user: any) => {
                this.isQuipexAdmin.set(user?.role === 'Quipex Admin');
                this._currentUser = user;
            });

        this.getData();
        this.configureBuildingSearch();
        this.getBuildings();

        this.dialogService.requestCancel$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.dialogService.closeDialog());

        this.dialogService.requestConfirm$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.saveUser());
    }

    onBack(): void {
        this.dialogService.setCanConfirm(true);
        this.currentStep.set(AddUserStepsEnum.Contact);
        this.dialogService.updateConfirmLabel('Next');
        this.dialogService.updateConfirmIcon('chevron_right');
    }

    onBuildingChecked(): void {
        if (this.toggleCtrl.value) {
            this.toggleCtrl.setValue(false);
        } else {
            const hasUnselected = this.filteredBuildings$
                .getValue()
                .some((item) => !item.selected);
            if (!hasUnselected) {
                this.toggleCtrl.setValue(true);
            } else if (this.toggleCtrl.value) {
                this.toggleCtrl.setValue(false);
            }
        }
    }

    onToggleAll(): void {
        if (this.toggleCtrl.value) {
            this.filteredBuildings$
                .getValue()
                .forEach((building) => (building.selected = true));
        } else {
            this.filteredBuildings$
                .getValue()
                .forEach((building) => (building.selected = false));
        }
    }

    private saveUser(): void {
        if (this.currentStep() === this.addUserStepsEnum.Contact) {
            this.currentStep.set(this.addUserStepsEnum.Buildings);
            this.dialogService.updateConfirmLabel('Save');
            this.dialogService.updateConfirmIcon('');
            this.dialogService.setCanConfirm(true);
        } else {
            const buildingIds = this.buildingsAll
                .filter((building) => building.selected)
                .map((b) => b.item.id);

            const newUser: ICreateUser = {
                createdByUserId: this._currentUser.internalId,
                displayName: this.form.controls['name'].value,
                emailAddress: this.form.controls['email'].value,
                organizationId: !this.isQuipexAdmin()
                    ? this._currentUser.orgId
                    : this.form.controls['organisation'].value?.id,
                roleId: this.form.controls['role'].value?.roleId,
                isActive: true,
                accessBuildingIds: buildingIds,
            };
            this.dialogService.setIsProcessing(true);
            this.userService
                .addUser(newUser)
                .pipe(finalize(() => this.dialogService.setIsProcessing(false)))
                .subscribe({
                    next: () => {
                        this.snackBar.open(
                            'User has been invited to Quipex',
                            'check_circle',
                            {
                                horizontalPosition: 'center',
                                verticalPosition: 'top',
                                panelClass: 'success',
                                duration: 1500,
                            }
                        );
                        this.dialogService.closeDialog(true);
                    },
                    error: (error: any) => {
                        this.snackBar.open(
                            error ?? 'Error while adding user',
                            'error',
                            {
                                horizontalPosition: 'center',
                                verticalPosition: 'top',
                                panelClass: 'danger',
                                duration: 1500,
                            }
                        );
                    },
                });
        }
    }

    private configureForm(): FormGroup {
        const form = this.formBuilder.group({});
        form.addControl('name', new FormControl('', [Validators.required]));
        form.addControl(
            'email',
            new FormControl('', [Validators.required, Validators.email])
        );
        form.addControl('organisation', new FormControl(null));
        form.addControl('role', new FormControl(null, [Validators.required]));
        form.addControl('buildingsSearch', new FormControl(''));
        form.addControl('toggle', new FormControl(true));

        if (this.isQuipexAdmin()) {
            (form as FormGroup)
                .get('organisation')
                ?.setValidators(Validators.required);
        }

        form.valueChanges
            .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    this.dialogService.setCanConfirm(this.form.valid);
                },
            });

        return form;
    }

    private getData(): void {
        const roles$ = this.permissionService.getRolesWithPermissions();
        const organisations$ = this.organizationService.getAllOrganizations();

        forkJoin([roles$, organisations$]).subscribe({
            next: ([roles, organisations]) => {
                this.roles = roles;
                this.organisations = organisations;
                this.cdr.markForCheck();
            },
        });
    }

    private configureBuildingSearch(): void {
        this.buildingsSearchCtrl.valueChanges
            .pipe(
                debounceTime(350),
                distinctUntilChanged(),
                filter((value) => {
                    if (typeof value === 'string' && value.trim() === '') {
                        this.filteredBuildings$.next([...this.buildingsAll]);
                    }
                    return typeof value === 'string' && value.trim() !== '';
                }),
                switchMap((value) => {
                    const filteredBuildings = this.buildingsAll.filter(
                        (building) =>
                            building.item.buildingName
                                .toLowerCase()
                                .indexOf(value.toLowerCase()) > -1
                    );
                    return of(filteredBuildings);
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((response) => {
                this.filteredBuildings$.next(response);
            });
    }

    private getBuildings(): void {
        this.accessService
            .getGrantedOrganizationsNames(this._currentUser.organizationId)
            .subscribe({
                next: (response: IBuildingName[]) => {
                    response.forEach((building) => {
                        const buildingSelect: IBuildingSelect = {
                            selected: true,
                            item: building,
                        };
                        this.buildingsAll.push(buildingSelect);
                    });
                    this.filteredBuildings$.next([...this.buildingsAll]);
                },
            });
    }
}
