<div class="notification">
    <div class="notification-icon" (click)="toggleNotifications()">
        <mat-icon class="material-symbols-outlined"> notifications </mat-icon>
        <span *ngIf="unreadCount > 0" class="notification-badge">{{
            unreadCount
        }}</span>
    </div>

    <div class="notification-dropdown" *ngIf="isDropdownOpen">
        <div class="header">
            <span>Notifications</span>
            <span class="close-icon" (click)="toggleNotifications()">
                <mat-icon>close</mat-icon>
            </span>
        </div>

        <div class="notification-list">
            <div
                *ngIf="notifications.length === 0"
                class="notification-item title"
            >
                No new notifications to display
            </div>
            <div
                class="notification-item"
                *ngFor="let notification of notifications"
            >
                <div
                    class="bullet"
                    [ngClass]="{
                        'bullet-overdue': isOverdue(notification.description),
                        'bullet-upcoming': !isOverdue(notification.description),
                    }"
                ></div>
                <div
                    class="notification-content"
                    (click)="navigateToDetails(notification)"
                >
                    <div class="title">{{ notification.title }}</div>
                    <div class="subtitle">{{ notification.description }}</div>
                    <div class="date">
                        {{ notification.createdAt | date: 'd MMM' }}
                    </div>
                </div>

                <div
                    class="mark-as-read"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Mark as read"
                    (click)="markAsRead(notification.id)"
                >
                    <mat-icon>visibility</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>
