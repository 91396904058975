import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiServiceBase } from '../api-service-base';
import {
    IBuildingName,
    IGetGrantedBuildingsResponse,
    IGetGrantedOrganisationsResponse,
    IOrganisationGrantedUser,
} from '../models';
import {
    IAccessOrganisation,
    IAccessOrganisationSearch,
    IDelegatingBMAccessCommandRequest,
    IEditDelegatingBMAccessRequest,
    IGetAccessGrantedOrganizationsRequestDTO,
} from '../models/typings/user-access.interface';

@Injectable({
    providedIn: 'root',
})
export class AccessApiService extends ApiServiceBase {
    private apiAccessBase = `${this.appConfig.apiBase}/api/accesses`;

    getOrganizations(buildingId: string): Observable<IAccessOrganisation[]> {
        const url = `${this.apiAccessBase}/buildings/${buildingId}/granted-organizations`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getAccessOrganizationsNames(
        searchTerm: string
    ): Observable<IAccessOrganisationSearch[]> {
        const url = `${this.apiAccessBase}/organizations/${searchTerm}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getGrantedOrganizationsNames(orgId: string): Observable<IBuildingName[]> {
        const url = `${this.apiAccessBase}/granted-organization-buildings-names/${orgId}`;
        return this.http.get<IBuildingName[]>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getGrantedOrganizations(
        postBody: IGetAccessGrantedOrganizationsRequestDTO
    ): Observable<IGetGrantedOrganisationsResponse> {
        const url = `${this.apiAccessBase}/granted`;
        return this.http
            .post<IGetGrantedOrganisationsResponse>(url, postBody)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getGrantedBuildings(
        grantId: string
    ): Observable<IGetGrantedBuildingsResponse> {
        const url = `${this.apiAccessBase}/granted/${grantId}/buildings`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getGrantedUsers(
        buildingId: string,
        orgId: string
    ): Observable<IOrganisationGrantedUser[]> {
        const url = `${this.apiAccessBase}/users/${buildingId}/${orgId}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    updateGrantedBuildingAccess(
        postBody: IEditDelegatingBMAccessRequest
    ): Observable<any> {
        const url = `${this.apiAccessBase}/access`;
        return this.http.put<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    delegateAccess(
        postBody: IDelegatingBMAccessCommandRequest
    ): Observable<any> {
        const url = `${this.apiAccessBase}/delegate-bm-access`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }
}
