import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BHCOverallScoreCard } from '@quipex/shared/data';
import { HealthRatingComponent } from '../health-rating/health-rating.component';

@Component({
    selector: 'qpx-building-score-widget',
    templateUrl: './building-score-widget.component.html',
    styleUrls: ['./building-score-widget.component.scss'],
    imports: [CommonModule, MatIconModule, HealthRatingComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingScoreWidgetComponent {
    bhcScoreCardOverall = input<BHCOverallScoreCard | null>(null);
    bhcCultureScoreCardOverall = input<BHCOverallScoreCard | null>(null);
}
