import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    inject,
    output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocomplete,
    MatAutocompleteModule,
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { DocumentApiService, Tag } from '@quipex/shared/data';
import { Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'qpx-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        ReactiveFormsModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsComponent implements OnInit, OnDestroy {
    @Input() set selected(value: Array<Tag>) {
        this.tags = value;
    }

    tagSelectionChange = output<void>();

    @ViewChild('tagInput') tagInputEl!: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete!: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger)
    autocompleteTrigger!: MatAutocompleteTrigger;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagControl = new FormControl('');
    filteredTags: Observable<Tag[]> | undefined;
    tags: Tag[] = [];

    private readonly documentService = inject(DocumentApiService);

    ngOnInit(): void {
        this.filteredTags = this.tagControl.valueChanges.pipe(
            startWith(null),
            filter((value: string | null) => typeof value === 'string'),
            switchMap((value) => this._filter(value)) // Switch to the new observable
        );

        window.addEventListener('scroll', this.onScroll, true);
    }

    /**
     * Handles the scroll event for the autocomplete panel.
     * If the autocomplete panel is open, it updates its position based on the scroll event.
     */
    onScroll = (event: any): void => {
        if (this.autocompleteTrigger.panelOpen) {
            this.autocompleteTrigger.updatePosition();
        }
    };

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if (value) {
            const isOptionSelected = this.matAutocomplete.options.some(
                (option) => option.selected
            );
            if (!isOptionSelected) {
                const newTag: Tag = {
                    tagName: value,
                    keyDocumentType: null,
                };
                this.tags.push(newTag);
            }
        }

        event.chipInput!.clear();
        this.tagControl.setValue(null);
    }

    remove(tag: Tag): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
        }

        this.tagInputEl?.nativeElement.focus();
    }

    selectedTag(event: MatAutocompleteSelectedEvent): void {
        const tag = new Tag();
        tag.tagName = event.option.value.tagName;
        tag.keyDocumentType = event.option.value.keyDocumentType;

        this.tags.push(tag);
        this.tagSelectionChange.emit();

        this.tagInputEl.nativeElement.value = '';
        this.tagControl.setValue('');
        this.matAutocomplete.options.forEach((data: MatOption) =>
            data.deselect()
        );
    }

    private _filter(value: string | null): Observable<Tag[]> {
        if (!value) {
            return of([]);
        }

        const newTag = {
            keyDocumentType: null,
            tagName: value,
        } as Tag;

        if (value.length > 2) {
            return this.documentService
                .getAllTags(value)
                .pipe(map((response) => [newTag].concat(response)));
        } else {
            // Return an empty observable if the input value is not long enough
            return of([newTag]);
        }
    }

    ngOnDestroy(): void {
        window.removeEventListener('scroll', this.onScroll, true);
    }
}
