import { CanDeactivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export type CanDeactivateType =
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree;

export interface ICanComponentDeactivate {
    canDeactivate: () => CanDeactivateType;
}

export const canDeactivateGuard: CanDeactivateFn<ICanComponentDeactivate> = (
    component: ICanComponentDeactivate
) => {
    return component.canDeactivate ? component.canDeactivate() : true;
};
