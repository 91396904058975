@if (viewType() !== 'tags-only') {
    <qpx-file-upload
        [buildingId]="buildingId"
        [shouldCheckForDuplicates]="shouldCheckForDuplicates()"
        [multiple]="'multiple'"
        [withDragAndDrop]="true"
        [isLoading]="!isLoaded()"
        (processingChange)="fileProcessingChange($event)"
        (fileListChange)="onFileUploadListChanged($event)"
    />
}

<div class="file-list">
    @if (viewType() === 'upload-with-tags' || viewType() === 'tags-only') {
        @if (!isLoaded()) {
            <ng-container
                *ngTemplateOutlet="loadingTpl() || defaultLoadingTpl"
            />
        } @else if (files?.length) {
            <div>
                <qpx-file-list
                    [files]="files"
                    (fileListChange)="onFileListChange()"
                />
            </div>
        }
    } @else {
        @if (!isLoaded()) {
            <ng-container
                *ngTemplateOutlet="loadingTpl() || defaultLoadingTpl"
            />
        } @else {
            @for (file of files; track file.id) {
                <div class="file-item">
                    <span>{{ file.file.name }}</span>
                    <button
                        type="button"
                        class="unstyled flex"
                        (click)="onFileRemove(file.id)"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            }
        }
    }
</div>

<ng-template #defaultLoadingTpl>
    <qpx-loading-screen />
</ng-template>
