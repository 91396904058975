import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    inject,
    input,
    signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import {
    BuildingApiService,
    EsmCategoryEnum,
    EsmCategoryNamesEnum,
    IGetBuildingAletsResponse,
} from '@quipex/shared/data';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { finalize } from 'rxjs';
import { LoaderComponent } from '../loader.component';
import { AddressPipe } from '@quipex/shared/pipes';

interface ICategoryInfo {
    categoryId: number;
    category: string;
    criticalCount: number;
    isCompliant: boolean;
    overdueCount: number;
}

@Component({
    selector: 'qpx-building-alerts-widget',
    templateUrl: './building-alerts-widget.component.html',
    styleUrls: ['./building-alerts-widget.component.scss'],
    imports: [
        CommonModule,
        MatIconModule,
        MatTableModule,
        InfiniteScrollModule,
        LoaderComponent,
        AddressPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingAlertsWidgetComponent implements OnInit {
    expanded = input<boolean>();
    isLoading = signal(true);

    displayedColumns: string[] = [
        'building',
        'fire',
        'lights',
        'air',
        'custom',
    ];
    dataSource = signal<ICategoryInfo[]>([]);
    payload = {
        pageSize: 16,
        pageNo: 1,
    };

    esmCategoryEnum = EsmCategoryEnum;
    esmCategoryNamesEnum = EsmCategoryNamesEnum;

    private readonly router = inject(Router);
    private readonly buildingService = inject(BuildingApiService);

    ngOnInit(): void {
        this.getBuildingAlerts();
    }

    navigateToBuildingOverview(
        isAccessible: boolean,
        buildingGuid: string
    ): void {
        if (!isAccessible) {
            return;
        }
        this.router.navigateByUrl(`buildings/${buildingGuid}`);
    }

    navigateToEsm(
        isAccessible: boolean,
        category: number,
        buildingGuid: string,
        masterName: string
    ): void {
        if (!category || !isAccessible) {
            return;
        }
        this.router.navigateByUrl(`buildings/${buildingGuid}/maintenance`, {
            state: {
                category,
                masterName,
            },
        });
    }

    onScroll(): void {
        this.payload.pageNo++;
        this.buildingService
            .getBuildingAlerts(this.payload)
            .subscribe((response: IGetBuildingAletsResponse) => {
                const parsedResponse = this.parseResponse(response);
                this.dataSource.update((values) => {
                    return values.concat(parsedResponse);
                });
            });
    }

    private getBuildingAlerts(): void {
        this.dataSource.set([]);
        this.buildingService
            .getBuildingAlerts(this.payload)
            .pipe(finalize(() => this.isLoading.set(false)))
            .subscribe({
                next: (response: IGetBuildingAletsResponse) => {
                    const parsedResponse = this.parseResponse(response);
                    this.dataSource.set(parsedResponse);
                },
            });
    }

    private parseResponse(response: IGetBuildingAletsResponse): any[] {
        const buildingAlerts: any = [];
        response.items.forEach((item) => {
            const buildingAlert = {
                buildingId: '',
                isAccessible: true,
                buildingAddress: '',
                fire: {},
                air: {},
                lights: {},
                custom: {},
            };

            buildingAlert.buildingAddress = item.buildingAddress;
            buildingAlert.buildingId = item.buildingId;
            buildingAlert.isAccessible = item.isAccessible;

            item.esmCategories.forEach((cat) => {
                const categoryItem: ICategoryInfo = {
                    categoryId: cat.categoryId,
                    category: cat.category,
                    criticalCount: cat.criticalCount,
                    isCompliant: cat.isCompliant,
                    overdueCount: cat.overdueCount,
                };

                if (
                    cat.categoryId ===
                    this.esmCategoryEnum.AIR_HANDLING_WATER_SYSTEMS
                ) {
                    buildingAlert.air = categoryItem;
                } else if (
                    cat.categoryId ===
                    this.esmCategoryEnum.FIRE_PROTECTION_SYSTEMS_EQUIPMENT
                ) {
                    buildingAlert.fire = categoryItem;
                } else if (
                    cat.categoryId ===
                    this.esmCategoryEnum.EMERGENCY_LIGHTING_EXIT_SIGNS
                ) {
                    buildingAlert.lights = categoryItem;
                } else {
                    buildingAlert.custom = categoryItem;
                }
            });
            buildingAlerts.push(buildingAlert);
        });
        return buildingAlerts;
    }
}
