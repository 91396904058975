<ng-container *ngIf="data?.standaloneDialog">
    <h2 mat-dialog-title>
        <span class="flex flex-grow">{{ data.dialogTitle }}</span>
        <mat-icon (click)="onCancel()">close</mat-icon>
    </h2>
</ng-container>

<ng-container [ngSwitch]="fileType">
    <!--PDF Type-->
    <ngx-extended-pdf-viewer
        *ngSwitchCase="'pdf'"
        (pdfLoaded)="contentLoaded()"
        [src]="docUrl"
        [height]="'80vh'"
        [showHandToolButton]="true"
        [showToolbar]="true"
        [showSidebarButton]="true"
        [showFindButton]="true"
        [showPagingButtons]="false"
        [showDrawEditor]="false"
        [showStampEditor]="false"
        [showTextEditor]="false"
        [showZoomButtons]="false"
        [showPresentationModeButton]="false"
        [showOpenFileButton]="false"
        [showPrintButton]="false"
        [showDownloadButton]="true"
        [showSecondaryToolbarButton]="false"
        [showRotateButton]="false"
        [showScrollingButtons]="false"
        [showSpreadButton]="false"
        [showPropertiesButton]="false"
        [textLayer]="true"
    >
    </ngx-extended-pdf-viewer>

    <qpx-loader *ngIf="!isLoaded"></qpx-loader>
    <!--Image   Type-->
    <div *ngSwitchCase="'image'" class="image-wrapper">
        <img [src]="docUrl" />
    </div>

    <!--Document Type e.g docx, doc, ppt, xlsx-->
    <ngx-doc-viewer
        *ngSwitchDefault
        [googleCheckInterval]="2000"
        [googleCheckContentLoaded]="true"
        [googleMaxChecks]="5"
        (loaded)="contentLoaded()"
        [url]="docUrl"
        viewer="google"
        [style.height]="'80vh'"
    ></ngx-doc-viewer>
</ng-container>
