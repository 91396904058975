// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'src/environments/environment';

export class FileHelper {
    static extractFileExtension(filename: string) {
        const re: any = /(?:\.([^.]+))?$/;
        const ext = re.exec(filename)[1];
        return ext;
    }
    static extractFileNameWithoutExtension(filename: string) {
        const lastDotPosition = filename.lastIndexOf('.');
        if (lastDotPosition === -1) return filename;
        else return filename.substring(0, lastDotPosition);
    }

    static checkFileUploadType(fileName: string) {
        return environment.documentUploadTypes.includes(
            FileHelper.extractFileExtension(fileName.toLowerCase())
        );
    }

    static isFileAutoTagType(fileName: string) {
        return environment.documentAutoTagTypes.includes(
            FileHelper.extractFileExtension(fileName.toLowerCase())
        );
    }

    /**
     * Extract the base64 string out of a File using FileReader Class
     * @param file File object
     * @returns a Promise
     */
    static getBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        }) as any;
    }

    /**
     * Parses a base64 string with header information and returns the raw string value
     * @param base64String
     * @returns base64 string without header information
     */
    static getBase64StringRaw(base64String: any) {
        return base64String.substring(base64String.lastIndexOf(',') + 1);
    }
}
