import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    inject,
    signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
    BuildingApiService,
    IGetCultureScoresResponse,
    IGetIntegrityScoresResponse,
} from '@quipex/shared/data';
import { finalize } from 'rxjs';
import { HealthRatingComponent } from '../health-rating/health-rating.component';
import { LoaderComponent } from '../loader.component';
import { AddressPipe } from '@quipex/shared/pipes';

enum SortOrderEnum {
    Ascending = 1,
    Descending = 2,
}

@Component({
    selector: 'qpx-building-scores',
    templateUrl: './building-scores.component.html',
    styleUrls: ['./building-scores.component.scss'],
    imports: [
        CommonModule,
        MatIconModule,
        ScrollingModule,
        LoaderComponent,
        HealthRatingComponent,
        AddressPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingScoresComponent implements OnInit {
    @Input() type: 'integrityScore' | 'riskCulture' = 'integrityScore';

    isLoading = signal(false);
    buildingScores = signal<any[]>([]);
    selectedSortOrder = 1;

    sortOrderEnum = SortOrderEnum;

    private readonly router = inject(Router);
    private readonly buildingService = inject(BuildingApiService);

    ngOnInit(): void {
        this.fetchBuildingScores();
    }

    setActiveTab(tab: SortOrderEnum): void {
        this.selectedSortOrder = tab;
        this.fetchBuildingScores();
    }

    navigateTo(isAccessible: boolean, id: string): void {
        if (!isAccessible) {
            return;
        }
        this.router.navigate(['/buildings', id]);
    }

    private fetchBuildingScores(): void {
        this.isLoading.set(true);
        if (this.type === 'integrityScore') {
            this.buildingService
                .getBuildingIntegrityScores(this.selectedSortOrder)
                .pipe(finalize(() => this.isLoading.set(false)))
                .subscribe((response: IGetIntegrityScoresResponse[]) => {
                    this.buildingScores.set(response);
                });
        } else {
            this.buildingService
                .getBuildingCultureScores(this.selectedSortOrder)
                .pipe(finalize(() => this.isLoading.set(false)))
                .subscribe((response: IGetCultureScoresResponse[]) => {
                    this.buildingScores.set(response);
                });
        }
    }
}
