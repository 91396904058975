import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiService } from '@quipex/shared/data';
import { filter, map } from 'rxjs';

export const assetRegisterGuard = () => {
    const userService = inject(UserApiService);
    const router = inject(Router);

    return userService.userMe$.pipe(
        filter((currentUser) => currentUser !== null),
        map((currentUser) => {
            if (currentUser) {
                const canReadAsset = currentUser.permissions?.find(
                    (permission) => permission.toLowerCase() === 'read_asset'
                );
                if (!canReadAsset) {
                    router.navigateByUrl('/buildings');
                    return false;
                }
                return true;
            }
            return false;
        })
    );
};
