import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Inject,
    OnInit,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {
    BuildingApiService,
    IBuildingClass,
    IOCDetailsExtraction,
    MiscApiService,
} from '@quipex/shared/data';
import { finalize, forkJoin } from 'rxjs';
import { LoaderComponent } from '../loader.component';
import dayjs from 'dayjs';

export interface IReviewBuildingData {
    buildingId: string;
    ocDetailsExtraction: IOCDetailsExtraction;
}

export interface IReviewBuildingItem {
    label: string;
    propertyName: string;
    currentValue: string;
    newValue: string;
}

enum AllowedOCFieldsEnum {
    Title = 'title',
    BuildingClasses = 'buildingClasses',
    RiseInStoreys = 'riseInStoreys',
    EffectiveHeight = 'effectiveHeight',
    About = 'about',
    MaxOccupants = 'maxOccupants',
    OccupancyDate = 'ocIssueDate',
}
@Component({
    selector: 'qpx-review-building-details',
    templateUrl: './review-building-details.component.html',
    styleUrls: ['./review-building-details.component.scss'],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatSnackBarModule,
        LoaderComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewBuildingDetailsComponent implements OnInit {
    isLoading = signal<boolean>(false);
    isSaving = signal<boolean>(false);

    form!: FormGroup;
    buildingClasses: IBuildingClass[] = [];

    private _buildingDetails!: any;
    private _buildingClassesUpdate: IBuildingClass[] | null = null;
    private _ocIssueDate: string | null = null;

    get acceptAllCtrl(): FormControl {
        return this.form?.controls['acceptAll'] as FormControl;
    }

    get itemsCtrl(): FormArray {
        return this.form?.controls['items'] as FormArray;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IReviewBuildingData,
        private readonly dialogRef: MatDialogRef<ReviewBuildingDetailsComponent>,
        private readonly formBuilder: FormBuilder,
        private readonly destroyRef: DestroyRef,
        private readonly cdr: ChangeDetectorRef,
        private readonly snackBar: MatSnackBar,
        private readonly buildingService: BuildingApiService,
        private readonly allBuildingService: MiscApiService
    ) {}

    ngOnInit(): void {
        this.getBuildingDetails(this.data.buildingId);
    }

    onAcceptAllChange(): void {
        this.itemsCtrl.controls.forEach((group) => {
            (group as FormGroup).controls['radioValue'].setValue(
                this.acceptAllCtrl.value,
                { emitEvent: false }
            );
        });
    }

    onSaveClicked(): void {
        const acceptedGroups = this.itemsCtrl.controls.filter(
            (group: any) => group.controls['radioValue'].value
        );

        if (!acceptedGroups.length) {
            this.dialogRef.close();
            return;
        }

        const postBody = {
            buildingId: this._buildingDetails.buildingId,
        } as any;

        acceptedGroups.forEach((group: any) => {
            const propertyName = group.controls['propertyName'].value;
            const valueToSave = group.controls['newValue'].value;

            if (propertyName === AllowedOCFieldsEnum.Title) {
                postBody.title = valueToSave;
            } else if (propertyName === AllowedOCFieldsEnum.BuildingClasses) {
                postBody.classId = this._buildingClassesUpdate?.map(
                    (buildingCalss) => buildingCalss.classGuid
                );
            } else if (propertyName === AllowedOCFieldsEnum.RiseInStoreys) {
                postBody.riseInStoreys = valueToSave;
            } else if (propertyName === AllowedOCFieldsEnum.MaxOccupants) {
                postBody.maxOccupants = valueToSave;
            } else if (propertyName === AllowedOCFieldsEnum.EffectiveHeight) {
                postBody.effectiveHeight = valueToSave;
            } else if (propertyName === AllowedOCFieldsEnum.About) {
                postBody.about = valueToSave;
            } else if (propertyName === AllowedOCFieldsEnum.OccupancyDate) {
                postBody.occupancyCertificateDate = this._ocIssueDate;
            }
            return null;
        });

        this.isSaving.set(true);
        this.buildingService
            .updateBuilding(postBody)
            .pipe(finalize(() => this.isSaving.set(false)))
            .subscribe({
                next: () => {
                    this.snackBar.open(
                        'Building details updated sucessfully',
                        'check_circle',
                        {
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                            panelClass: 'success',
                            duration: 1500,
                        }
                    );

                    this.dialogRef.close();
                },
            });
    }

    private getBuildingDetails(id: string): void {
        const buildingDetails$ = this.buildingService.getBuildingDetails(id);
        const buildingClasses$ = this.allBuildingService.fetchClasses();

        this.isLoading.set(true);
        forkJoin([buildingDetails$, buildingClasses$])
            .pipe(
                finalize(() => this.isLoading.set(false)),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([buildingDetails, buildingClasses]) => {
                this.buildingClasses = buildingClasses;

                this._buildingDetails = buildingDetails;

                this.form = this.configureForm(this.data.ocDetailsExtraction);
                this.subscribeForValueChanges();

                this.cdr.markForCheck();
            });
    }

    private configureForm(details: IOCDetailsExtraction): FormGroup {
        const form = this.formBuilder.group({});
        const formArray = new FormArray<any>([]);
        form.addControl('acceptAll', new FormControl(true));
        form.addControl('items', formArray);

        for (let key in details) {
            if (Object.values(AllowedOCFieldsEnum).includes(<any>key)) {
                this.addToFormArray(formArray, details, key);
            }
        }

        return form;
    }

    private addToFormArray(
        formArray: FormArray,
        details: IOCDetailsExtraction,
        key: any
    ): void {
        const labelName = this.getLabelName(key);
        const currentValue = this.getCurrentValueFromBuildingDetails(key);
        let newValue = details[key as keyof IOCDetailsExtraction];

        if (key === AllowedOCFieldsEnum.About && Array.isArray(newValue)) {
            newValue = newValue.toString();
        }

        if (key === AllowedOCFieldsEnum.BuildingClasses) {
            this._buildingClassesUpdate =
                this.buildingClasses.filter(
                    (buildingClass) =>
                        details.buildingClasses?.indexOf(buildingClass.name) !==
                        -1
                ) ?? [];
            newValue = this._buildingClassesUpdate.map(
                (buildingClass) => buildingClass.name
            );
        }

        if (
            key === AllowedOCFieldsEnum.OccupancyDate &&
            typeof newValue === 'string'
        ) {
            this._ocIssueDate = newValue;
            newValue = dayjs(newValue).format('DD-MMM-YYYY');
        }

        if (newValue) {
            formArray.push(
                this.configureLineItem(labelName, key, currentValue, newValue)
            );
        }
    }

    private configureLineItem(
        label: string,
        propertyName: string,
        currentValue: string,
        newValue: any
    ): FormGroup {
        const formGroup = this.formBuilder.group({
            label: new FormControl(label),
            propertyName: new FormControl(propertyName),
            oldValue: new FormControl({ value: currentValue, disabled: true }),
            newValue: new FormControl({ value: newValue, disabled: true }),
            radioValue: new FormControl(true, [Validators.required]),
        });

        return formGroup;
    }

    private subscribeForValueChanges(): void {
        this.itemsCtrl.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    const allSelected = this.itemsCtrl.controls.every(
                        (group: any) => group.controls['radioValue'].value
                    );
                    const allUnselected = this.itemsCtrl.controls.every(
                        (group: any) =>
                            group.controls['radioValue'].value !== null &&
                            !group.controls['radioValue'].value
                    );

                    if (allSelected) {
                        this.acceptAllCtrl.setValue(true);
                    } else if (allUnselected) {
                        this.acceptAllCtrl.setValue(false);
                    } else {
                        this.acceptAllCtrl.setValue(null);
                    }
                },
            });
    }

    private getLabelName(propertyName: string): string {
        if (propertyName === AllowedOCFieldsEnum.Title) {
            return 'Title details';
        } else if (propertyName === AllowedOCFieldsEnum.BuildingClasses) {
            return 'Class';
        } else if (propertyName === AllowedOCFieldsEnum.RiseInStoreys) {
            return 'Rise in storeys';
        } else if (propertyName === AllowedOCFieldsEnum.MaxOccupants) {
            return 'Max occupants';
        } else if (propertyName === AllowedOCFieldsEnum.EffectiveHeight) {
            return 'Effective height (meters)';
        } else if (propertyName === AllowedOCFieldsEnum.About) {
            return 'About';
        } else if (propertyName === AllowedOCFieldsEnum.OccupancyDate) {
            return 'Occupancy Issued';
        }

        return '';
    }

    private getCurrentValueFromBuildingDetails(propertyName: string): any {
        if (propertyName === AllowedOCFieldsEnum.Title) {
            return this._buildingDetails.title;
        } else if (propertyName === AllowedOCFieldsEnum.BuildingClasses) {
            return this._buildingDetails.classes;
        } else if (propertyName === AllowedOCFieldsEnum.RiseInStoreys) {
            return this._buildingDetails.riseInStoreys;
        } else if (propertyName === AllowedOCFieldsEnum.MaxOccupants) {
            return this._buildingDetails.maxOccupants;
        } else if (propertyName === AllowedOCFieldsEnum.EffectiveHeight) {
            return this._buildingDetails.effectiveHeight;
        } else if (propertyName === AllowedOCFieldsEnum.About) {
            return this._buildingDetails.about;
        } else if (propertyName === AllowedOCFieldsEnum.OccupancyDate) {
            return this._buildingDetails.occupancyCertificateDate
                ? dayjs(this._buildingDetails.occupancyCertificateDate).format(
                      'DD-MMM-YYYY'
                  )
                : '';
        }
    }
}
