import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    OnInit,
    inject,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { User, UserStore } from '@quipex/shared/data';
import { HasPermissionDirective } from '@quipex/shared/directives';

@Component({
    selector: 'qpx-user-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        HasPermissionDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
    user = signal<User | null>(null);
    initials = signal('');

    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);
    private readonly userStore = inject(UserStore);

    ngOnInit(): void {
        this.userStore
            .select('me')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((user: User) => {
                this.user.set(user);
                this.initials.set(this.getUserInitials(user));
            });
    }

    navigateToManageUser(): void {
        this.router.navigate(['/admin/users', this.user()?.id], {
            state: {
                refresh: true,
            },
        });
    }

    private getUserInitials(user: User): string {
        let initials = '';
        const splitArr = user.name?.split(' ');
        if (!splitArr) {
            return initials;
        }
        if (splitArr.length) {
            initials = splitArr[0].substring(0, 1);
        }
        if (splitArr.length > 1) {
            initials = `${initials}${splitArr[1].substring(0, 1)}`;
        }
        return initials;
    }
}
