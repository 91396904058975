@if (withDragAndDrop) {
    <div class="drag-drop-area" appDnd (fileDropped)="onFileDropped($event)">
        <!-- Text above the button -->
        <div>Drag and drop your documents here, or</div>
        <!-- Upload button in the center -->
        <div class="drag-drop-area__button">
            <input
                #fileInput
                type="file"
                style="display: none"
                [disabled]="!uploadEnabled"
                [multiple]="multiple"
                (change)="fileBrowseHandler($event)"
                (click)="onFileInputClick($event)"
            />
            <button
                mat-stroked-button
                [disabled]="!uploadEnabled"
                (click)="openFileBrowser()"
            >
                <mat-icon class="qpx-icon" color="accent">upload</mat-icon>
                Browse Files
            </button>
        </div>
    </div>
} @else {
    @if (buttonType) {
        <div (click)="fileInput.click()">
            <input
                #fileInput
                type="file"
                [multiple]="multiple"
                class="input-file"
                style="display: none"
                (change)="fileBrowseHandler($event)"
                (click)="onFileInputClick($event)"
            />
            <button mat-stroked-button id="uploadFile" type="button">
                <mat-icon class="qpx-icon-sm">upload</mat-icon>
                Upload File
            </button>
        </div>
    } @else {
        <div class="flex align-center" (click)="fileInput.click()">
            <input
                #fileInput
                type="file"
                style="display: none"
                [multiple]="multiple"
                (change)="fileBrowseHandler($event)"
                (click)="onFileInputClick($event)"
            />
            Upload
            <mat-icon class="qpx-icon" color="accent">upload</mat-icon>
        </div>
    }
}
