<mat-form-field class="no-error filter-form-field" [ngClass]="inputClasses">
    <mat-select
        #matSelect
        [formControl]="filterItemsCtrl"
        [panelClass]="selectPanelClasses"
        placeholder="{{ adjustedPlaceholder() }}"
        multiple
        (openedChange)="onOpenedChanged($event)"
    >
        <mat-select-trigger class="flex">
            @if (appliedFilterItemsCtrl.value?.length) {
                @if (counterWithPlaceholder) {
                    <div>
                        <span class="counter">{{
                            getTotalSelectedCounter()
                        }}</span
                        >{{ placeholder }}
                    </div>
                } @else {
                    @for (
                        item of appliedFilterItemsCtrl.value;
                        track item;
                        let last = $last
                    ) {
                        @if (item['id'] !== 0) {
                            {{ item['name'] }}
                            @if (!last) {
                                <span>,&nbsp;</span>
                            }
                        }
                    }
                }
            } @else {
                {{ placeholder }}
            }
        </mat-select-trigger>

        <div
            *ngIf="showHeader"
            class="flex justify-between align-center header-section"
        >
            <mat-label class="semi-bold">
                {{ getTotalSelectedLabel() }}
            </mat-label>
            <button
                mat-stroked-button
                type="button"
                [disabled]="!filterItemsCtrl.value?.length"
                (click)="clearAll()"
            >
                Clear All
            </button>
        </div>

        <div class="options">
            <ng-container *ngFor="let item of filterItem?.array">
                <mat-option
                    *ngIf="item.id !== 0"
                    [value]="item"
                    (click)="toggleSingle()"
                    >{{ item.name }}
                </mat-option>
                <mat-option
                    *ngIf="item.id === 0"
                    #allSelectedMatOption
                    [value]="item"
                    (click)="onToggleAll()"
                    >{{ item.name }}
                </mat-option>
            </ng-container>
        </div>

        <div class="flex justify-end align-center footer-section">
            <button mat-stroked-button type="button" (click)="applyFilters()">
                <mat-icon class="qpx-icon-sm"> done </mat-icon> Apply
            </button>
        </div>
    </mat-select>
</mat-form-field>
