import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
} from '@angular/common/http';
import { Router, ActivationStart } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpCancelService } from './http-cancel.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { HandleErrorService } from './handle-error.service';

@Injectable({
    providedIn: 'root',
})
export class ManageHttpInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private httpCancelService: HttpCancelService,
        private error: HandleErrorService
    ) {
        router.events.subscribe((event) => {
            if (event instanceof ActivationStart) {
                this.httpCancelService.cancelPendingRequests();
            }
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            map((event: any) => {
                return event;
            }),
            catchError((requestError) => {
                return this.error.handleError(requestError);
            })
        );
    }
}
