<div class="add-user">
    @if (form) {
        <form [formGroup]="form">
            @if (currentStep() === addUserStepsEnum.Contact) {
                <mat-label>Name *</mat-label>
                <mat-form-field class="w-100">
                    <input
                        matInput
                        maxlength="50"
                        data-testid="name"
                        formControlName="name"
                    />

                    @if (form.controls['name'].errors?.['required']) {
                        <mat-error>Name is required</mat-error>
                    }
                </mat-form-field>

                <mat-label>Email *</mat-label>
                <mat-form-field class="w-100">
                    <input
                        matInput
                        maxlength="50"
                        data-testid="email"
                        formControlName="email"
                    />

                    @if (form.controls['email'].errors?.['required']) {
                        <mat-error>Email is required</mat-error>
                    }

                    @if (form.controls['email'].errors?.['email']) {
                        <mat-error
                            >Please enter a valid email address</mat-error
                        >
                    }
                </mat-form-field>

                <ng-container *hasRole="['Quipex Admin']">
                    <mat-label
                        >Organisation
                        @if (isQuipexAdmin()) {
                            <span> *</span>
                        }
                    </mat-label>
                    <mat-form-field class="w-100">
                        <mat-select
                            #matSelectOrg
                            placeholder="Please select"
                            data-testid="organisationLink"
                            formControlName="organisation"
                        >
                            <mat-option
                                *ngFor="let organisation of organisations"
                                [value]="organisation"
                            >
                                {{ organisation.name }}
                            </mat-option>
                        </mat-select>

                        @if (
                            form.controls['organisation'].errors?.['required']
                        ) {
                            <mat-error>Organisation is required</mat-error>
                        }
                    </mat-form-field>
                </ng-container>

                <mat-label>Role *</mat-label>
                <mat-form-field class="w-100">
                    <mat-select
                        #matSelectRole
                        placeholder="Please select"
                        data-testid="roleLink"
                        formControlName="role"
                    >
                        <mat-option *ngFor="let role of roles" [value]="role">
                            {{ role.name }}
                        </mat-option>
                    </mat-select>

                    @if (form.controls['role'].errors?.['required']) {
                        <mat-error>Role is required</mat-error>
                    }
                </mat-form-field>
            }

            @if (currentStep() === addUserStepsEnum.Buildings) {
                <h1 class="dialog-content-title">Select buildings</h1>
                <mat-form-field class="w-100">
                    <mat-icon matPrefix>search</mat-icon>

                    <input
                        #buildingSearchEl
                        type="text"
                        name="buildingSearch"
                        spellcheck="false"
                        autocomplete="off"
                        placeholder="Search buildings"
                        matInput
                        maxlength="50"
                        [formControl]="buildingsSearchCtrl"
                    />

                    <mat-icon
                        matSuffix
                        class="cursor-pointer"
                        (click)="buildingsSearchCtrl.setValue('')"
                        >close</mat-icon
                    >
                </mat-form-field>

                <div class="buildings">
                    <ng-container
                        *ngIf="filteredBuildings$ | async as buildings"
                    >
                        @if (!buildings.length && buildingsSearchCtrl.value) {
                            <div class="empty">Search result empty</div>
                        }

                        @if (buildings.length) {
                            <mat-checkbox
                                color="primary"
                                class="squared"
                                [disableRipple]="true"
                                [formControl]="toggleCtrl"
                                (click)="onToggleAll()"
                            >
                                <span class="select-all">Select All</span>
                            </mat-checkbox>
                        }

                        @for (building of buildings; track building) {
                            <mat-checkbox
                                color="primary"
                                class="squared"
                                [disableRipple]="true"
                                [(ngModel)]="building.selected"
                                (ngModelChange)="onBuildingChecked()"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                {{ building.item.buildingName }}
                            </mat-checkbox>
                        }
                    </ng-container>
                </div>

                <div class="nav-buttons">
                    <button mat-button (click)="onBack()">
                        <mat-icon class="qpx-icon-sm"> chevron_left </mat-icon>
                        Back
                    </button>
                </div>
            }
        </form>
    } @else {
        <qpx-loader></qpx-loader>
    }
</div>
