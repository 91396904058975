/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import {
    MsalGuardConfiguration,
    MsalInterceptorConfiguration,
    ProtectedResourceScopes,
} from '@azure/msal-angular';
import {
    LogLevel,
    Configuration,
    BrowserCacheLocation,
    IPublicClientApplication,
    PublicClientApplication,
    InteractionType,
} from '@azure/msal-browser';

import { AppConfigModel } from './models/app-config.model';

const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest = {
    scopes: [],
};

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(
    appConfig: AppConfigModel
): IPublicClientApplication {
    /**
     * Configuration object to be passed to MSAL instance on creation.
     * For a full list of MSAL.js configuration parameters, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
     */
    const msalConfig: Configuration = {
        auth: {
            clientId: appConfig.clientId, // This is the ONLY mandatory field that you need to supply.
            authority: appConfig.b2cPolicies.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
            knownAuthorities: [appConfig.b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
            redirectUri: '/auth', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
            postLogoutRedirectUri: '/dashboard', // Points to window.location.origin by default.
        },
        cache: {
            cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
        },
        system: {
            /**
             * Below you can configure MSAL.js logs. For more information, visit:
             * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
             */
            loggerOptions: {
                loggerCallback(logLevel: LogLevel, message: string) {
                    //    console.log(message);
                },
                logLevel: LogLevel.Verbose,
                piiLoggingEnabled: false,
            },
        },
    };
    /**
     * Configuration object to be passed to MSAL instance on creation.
     * For a full list of MSAL.js configuration parameters, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
     */
    return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest,
    };
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(
    appConfig: AppConfigModel
): MsalInterceptorConfiguration {
    /**
     * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
     */

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
            /* List out all the apis that require auth and their corresponding scopes that have been
         setup for the client via adb2c */
            [appConfig.apiBase, appConfig.scopes['quipexApi']],
            [appConfig.addressApiBase, appConfig.scopes['addressApi']],
            [appConfig.apiFunctionBase, appConfig.scopes['functionApp']],
        ]),
    };
}
