<div class="widget">
    <div class="title">Building Health</div>

    <div class="widget__content">
        @if (isLoading()) {
            <qpx-loader></qpx-loader>
        } @else if (categories.length) {
            <div class="top-section">
                @for (category of categories.slice(0, 2); track category) {
                    <ng-container
                        *ngTemplateOutlet="
                            categoryItem;
                            context: { $implicit: category }
                        "
                    >
                    </ng-container>
                }
            </div>

            <div class="bottom-section">
                @for (category of categories.slice(2, 4); track category) {
                    <ng-container
                        *ngTemplateOutlet="
                            categoryItem;
                            context: { $implicit: category }
                        "
                    >
                    </ng-container>
                }
                @if (categories.length <= 3) {
                    <div class="widget-item empty"></div>
                }
            </div>
        } @else {
            <ng-container *ngTemplateOutlet="noDataMessage"></ng-container>
        }
    </div>
</div>

<ng-template #categoryItem let-category>
    <div
        class="widget-item"
        [ngClass]="{
            link: canNavigateToMaintenance(),
            'non-compliant': category.nonCompliantCount,
        }"
        (click)="navigateToEsm(category.esmCategory)"
    >
        <div class="widget-item__text">
            <span class="label">{{
                getCategoryLabel(category.esmCategory)
            }}</span>

            @if (
                category.nonCompliantCount === 0 &&
                category.compliantCount === 0
            ) {
                <span>N/A</span>
            } @else if (category.nonCompliantCount > 0) {
                <span>{{ category.nonCompliantCount }} not compliant</span>
            } @else {
                <span>Compliant</span>
            }
        </div>

        @if (
            !responseIsEmpty() &&
            (category.nonCompliantCount > 0 || category.compliantCount > 0)
        ) {
            @if (category.nonCompliantCount > 0) {
                <mat-icon class="warning">error</mat-icon>
            } @else {
                <mat-icon>check_circle</mat-icon>
            }
        }
    </div>
</ng-template>

<ng-template #noDataMessage>
    <div class="no-data-message">No data available.</div>
</ng-template>
