import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { HasPermissionDirective } from '@quipex/shared/directives';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    imports: [
        RouterLink,
        RouterLinkActive,
        RouterOutlet,
        HasPermissionDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminComponent implements OnInit {
    isProdEnvironment!: boolean;

    ngOnInit(): void {
        this.isProdEnvironment = environment.isProd;
    }
}
