import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export const insightsGuard = () => {
    const router = inject(Router);

    if (environment.isProd) {
        router.navigateByUrl('/');
        return false;
    } else {
        return true;
    }
};
