import { CommonModule, NgComponentOutlet } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogService, IDialogData } from '@quipex/shared/data';
import { Subject, takeUntil } from 'rxjs';
import { LoaderComponent } from '../loader.component';

@Component({
    selector: 'qpx-dialog',
    templateUrl: './dialog.component.html',
    imports: [
        CommonModule,
        NgComponentOutlet,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        LoaderComponent,
    ],
})
export class DialogComponent implements OnInit, OnDestroy {
    isProcessing = false;
    canConfirm!: boolean;

    private destroy$ = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: IDialogData,
        private dialogRef: MatDialogRef<DialogComponent>,
        private dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.canConfirm = this.dialogData.confirmEnabled || false;

        this.dialogService.isProcessing$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isProcessing) => (this.isProcessing = isProcessing));

        this.dialogService.canConfirm$
            .pipe(takeUntil(this.destroy$))
            .subscribe((canConfirm) => (this.canConfirm = canConfirm));

        this.dialogService.close$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isConfirm) => this.closeDialog(isConfirm));

        this.dialogService.confirmLabelChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe((label) => (this.dialogData.submitButtonLabel = label));

        this.dialogService.confirmIconChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe((icon) => (this.dialogData.submitButtonIcon = icon));

        this.dialogService.cancelButtonVisible$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (visible) =>
                    (this.dialogData.hideCancelButton = !visible),
            });

        this.dialogService.confirmButtonTheme$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (theme) => (this.dialogData.submitButtonTheme = theme),
            });

        this.dialogService.dialogTitle$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (title) => (this.dialogData.dialogTitle = title),
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onCancel(): void {
        this.dialogService.requestCancel();
    }

    onConfirm(): void {
        this.dialogService.requestConfirm();
    }

    private closeDialog(isConfirm: boolean): void {
        this.dialogRef.close(isConfirm);
    }
}
