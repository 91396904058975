import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    ConfirmationDialogComponent,
    ConfirmationType,
} from '@quipex/shared/components';
import { Subject } from 'rxjs';
import { DialogComponent } from '../../../../../shared/components/src/lib/dialog/dialog.component';
import { IDialogData } from '../models/typings/dialog-data.interface';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    isProcessing$: Subject<boolean> = new Subject<boolean>();
    canConfirm$: Subject<boolean> = new Subject<boolean>();
    close$: Subject<boolean> = new Subject<boolean>();
    requestCancel$: Subject<void> = new Subject<void>();
    requestConfirm$: Subject<void> = new Subject<void>();
    confirmLabelChange$: Subject<string> = new Subject<string>();
    confirmButtonTheme$: Subject<string> = new Subject<string>();
    confirmIconChange$: Subject<string> = new Subject<string>();
    cancelButtonVisible$: Subject<boolean> = new Subject<boolean>();
    dialogTitle$: Subject<string> = new Subject<string>();

    private matDialog = inject(MatDialog);

    openDialog(
        dialogData: IDialogData,
        panelClass: string[] = [],
        disableClose: boolean = true
    ): MatDialogRef<DialogComponent> {
        return this.matDialog.open(DialogComponent, {
            autoFocus: 'first-heading',
            restoreFocus: false,
            panelClass: panelClass,
            disableClose: disableClose,
            data: {
                dialogTitle: dialogData.dialogTitle,
                componentToLoad: dialogData.componentToLoad,
                componentToLoadData: dialogData.componentToLoadData,
                submitButtonLabel: dialogData.submitButtonLabel,
                submitButtonIcon: dialogData.submitButtonIcon,
                submitButtonTheme: dialogData.submitButtonTheme,
                cancelButtonLabel: dialogData.cancelButtonLabel,
                hideCancelButton: dialogData.hideCancelButton,
                confirmEnabled: dialogData.confirmEnabled,
            },
            position: {
                top:
                    typeof dialogData.position?.top === 'number' &&
                    dialogData.position?.top >= 0
                        ? `${dialogData.position.top}px`
                        : '',
                right:
                    typeof dialogData.position?.right === 'number' &&
                    dialogData.position?.right >= 0
                        ? `${dialogData.position.right}px`
                        : '',
                bottom:
                    typeof dialogData.position?.bottom === 'number' &&
                    dialogData.position?.bottom >= 0
                        ? `${dialogData.position.bottom}px`
                        : '',
                left:
                    typeof dialogData.position?.left === 'number' &&
                    dialogData.position?.left >= 0
                        ? `${dialogData.position.left}px`
                        : '',
            },
        });
    }

    closeDialog(isConfirm: boolean = false): void {
        this.close$.next(isConfirm);
    }

    setIsProcessing(isProcessing: boolean): void {
        this.isProcessing$.next(isProcessing);
    }

    setCanConfirm(canConform: boolean): void {
        this.canConfirm$.next(canConform);
    }

    requestCancel(): void {
        this.requestCancel$.next();
    }

    requestConfirm(): void {
        this.requestConfirm$.next();
    }

    updateConfirmLabel(label: string): void {
        this.confirmLabelChange$.next(label);
    }

    updateConfirmIcon(icon: string): void {
        this.confirmIconChange$.next(icon);
    }

    setDialogTitle(title: string): void {
        this.dialogTitle$.next(title);
    }

    cancelButtonVisible(visible: boolean): void {
        this.cancelButtonVisible$.next(visible);
    }

    setConfirmButtonTheme(theme: 'primary' | 'accent'): void {
        this.confirmButtonTheme$.next(theme);
    }

    openConfirmationDialog(
        title: string,
        text1: string = '',
        text2: string = '',
        type: ConfirmationType | null = ConfirmationType.Default,
        confirmButtonType: ConfirmationType | null = ConfirmationType.Default,
        confirmButtonText: string = 'OK',
        cancelButtonText: string = 'Cancel',
        showCloseIcon: boolean = false,
        cancelButtonVisible: boolean = false
    ): MatDialogRef<ConfirmationDialogComponent> {
        return this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                title: title,
                text1: text1,
                text2: text2,
                confirmButtonText: confirmButtonText,
                cancelButtonText: cancelButtonText,
                type: type,
                confirmButtonType: confirmButtonType,
                showCloseIcon: showCloseIcon,
                cancelButtonVisible: cancelButtonVisible,
            },
            panelClass: ['confirm-dialog'],
            disableClose: true,
        });
    }
}
